<template>

    <div>

        <template v-if="itemName === 'id'">
            {{ value }}
        </template>

        <template v-else-if="is_association">
            <v-autocomplete
                dense
                solo
                flat
                hide-details
                attach
                :background-color="getColorSelect || 'transparent'"
                class="colored_select"
                :class="{'non-editable': !canSave}"
                append-icon=""
                :item-text="(item) => item.label || item.name"
                item-value="id"
                v-model="item_value"
                :items="custom_data_linked_tables_values[association_model_name || itemName]"
                :menu-props="{minWidth:'200'}"
                @change="triggerChangeAssociation($event)"
                :readonly="!canSave || itemAssociation.non_editable"
            >
              <template v-slot:item="data">
                <span :style="{'background-color': data.item.color}">
                  {{data.item.label || data.item.name}}
                </span>
              </template>
            </v-autocomplete>
        </template>

        <template v-else>
          {{/*STRING, TEXT, FLOAT FIELDS*/}}
          <div
            v-if="['STRING'].includes(itemType) && headerData.string_input"
            class="calendar-cell"
            style="position: relative;"
          >
            <template
              v-if="headerData.string_input.type === 'date'"
            >
              <date-picker
                v-model.trim="item_value_date"
                :type="headerData.string_input.type"
                :valueType="headerData.string_input.format"
                :format="headerData.string_input.format"
                :show-second="false"
                :lang="$i18n.locale"
                :editable="!canSave || (readonlyMode || itemEditable)"
                :append-to-body="false"

                :disabled="checkDisableValidations(entity_field)"
                :disabled-date="(item) => checkDateValidations(item, entity_field)"
                :disabled-calendar-changer="(item) => checkDateValidations(item, entity_field)"
                :disabled-time="(item) => checkTimeValidations(item, entity_field)"
                :clearable="checkClearableValidations(entity_field)"
              />
            </template>
            <template
              v-if="headerData.string_input.type === 'time'"
            >
              <v-sheet width="120" color="transparent">
                <TimeInput
                  v-model.trim="item_value_date"
                  :label="''"
                  :hide-details="'auto'"

                  :field_key="itemName"
                  :item_obj="item_obj"
                  :entity_fields="entity_fields"
                  :outlined="false"
                  :solo="true"
                />
              </v-sheet>
            </template>
          </div>


            <div
              v-else-if="['STRING', 'TEXT', 'FLOAT', 'INTEGER', 'BIGINT'].includes(itemType)"
              style="position: relative;">
                <v-tooltip

                    attach
                    bottom
                    :disabled="!value"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :append-icon="fieldIcon()"
                          :value="value"
                          solo
                          :readonly="!canSave || (readonlyMode || itemEditable)"
                          :class="{'non-editable': itemEditable || !canSave, 'is-link': itemDisplayType === 'link', 'is-open': itemClickAction === 'open'}"
                          hide-details
                          :background-color="highlight_color || 'transparent'"
                          flat
                          single-line
                          dense
                          @click:append="triggerClick()"
                          @click="triggerClick()"
                          @blur="triggerChangeOnBlur($event)"
                          ref="refInputValue"
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <span>{{ value }}</span>
                </v-tooltip>
            </div>

            {{/*BOOL FIELDS*/}}
            <v-checkbox
                v-if="['BOOLEAN'].includes(itemType)"
                v-model="item_value_checkbox"
                hide-details
                class="mb-4"
                :readonly="!canSave || itemEditable"
            ></v-checkbox>

            {{/*DATE FIELDS*/}}
            <template
                v-if="['DATE'].includes(itemType)"
            >

                <v-tooltip
                    v-if="itemName === 'createdAt'"
                    left
                    attach
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip 
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        >
                            {{ formatDate(value, true) }}
                        </v-chip>
                    </template>
                    <span>{{ formatDate(value) }}</span>
                </v-tooltip> 

                <div 
                    v-else
                    class="calendar-cell"
                    style="position: relative;"
                >

                    <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        attach
                        :disabled="!canSave"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                dense
                                solo
                                flat
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                background-color="transparent"
                                :value="formatDate(item_value_date, false, true)"
                                :class="{'non-editable': !canSave}"
                                @input="triggerChange($event)"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="item_value_date"
                            no-title
                            :locale="i18n.locale"
                            scrollable
                        >
                        </v-date-picker>
                    </v-menu>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import i18n from "@/lang/i18n";
import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';
import TimeInput from "@/components/dynamic/TimeInput.vue";

export default {
  name: "DynamicTableCell",

  components: {
    TimeInput,
    DatePicker,
  },

  props: {
    item_obj: {
      type: Object,
      default: () => {},
    },
    entity_field: {
      type: Object,
      default: () => {},
    },
    entity_fields: {
      type: Object,
      default: () => {},
    },
      itemId: {
          type: Number
      },
      itemName: {
          type: String
      },
      value: {
          type: [String, Number],
          default: ''
      },
      itemType: {
          type: String
      },
      itemEditable: {
          type: Boolean,
          default: false
      },
      itemDisplayType: {
          type: String
      },
      itemClickAction: {
          type: String
      },
      itemAssociation: {
        type: Object
      },

      highlight_color: {
        type: String
      },

      headerData: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
        i18n,
        readonlyMode: true,
      hasError: false
    }),

    computed: {
      ...mapGetters([
        'user',
        'custom_data_linked_tables_values',
      ]),

      item_value: {
        get: function() {
          const arr = this.custom_data_linked_tables_values[this.association_model_name || this.itemName]
          return arr.find((i) => i.name === this.value)
        },
        set: function(val){
          this.triggerChange(val)
        }
      },

      getColorSelect() {
        return this.item_value?.color || false
      },

      is_association() {
        if(this.itemAssociation){
          return !!this.custom_data_linked_tables_values?.[this.itemAssociation.name];
        }else {
          return !!this.custom_data_linked_tables_values?.[this.itemName];
        }
      },

        item_value_date: {
            get: function() {
                return this.value
            },
            set: function(val){
                this.triggerChange(val)
            }
        },

        item_value_checkbox: {
            get: function() {
                return this.value
            },
            set: function(val){
                this.triggerChange(val)
            }
        },

        canSave() {
          const routeName = this.$route?.name
          let permissionByRoute = null

          switch(routeName) {
            case 'Models': //Custom Data
              permissionByRoute = this.user.role.permissions.custom_data.sections.custom_data.canSave
              break;
            case 'BroadcastUsersUsers':
              permissionByRoute = this.user.role.permissions.broadcastUsers.route.haveRights
              break;
            default:
              permissionByRoute = this.user.role.permissions.custom_data.sections.custom_data.canSave;
              break;
          }

          return this.user && permissionByRoute;
        },

      association_model_name() {
        return this.itemAssociation?.name || null
      },
    },

    methods: {
        triggerClick() {
            this.readonlyMode = false;

            if (this.itemDisplayType === 'link') {
                window.open(this.addHttpIfMissing(this.value));
            }

            if (this.itemClickAction === 'open') {
                this.$emit('open-edit', this.itemId);
            }
        },

      triggerChangeAssociation(value) {
        const field = this.itemAssociation

        if(field?.auto_select_associated_on_change?.length){
          for(const i in field?.auto_select_associated_on_change){
            const index = this.findIndexWithAttr(
                this.custom_data_linked_tables_values[this.association_model_name || this.itemName],
              'name',
              value
            )

            if(
              index >= 0 &&
              this.custom_data_linked_tables_values[field.auto_select_associated_on_change[i]]
            ) {
              const val = this.custom_data_linked_tables_values[field.auto_select_associated_on_change[i]]
                .find((item) => item.is_id === this.custom_data_linked_tables_values[this.association_model_name][index]?.is_id)?.name

              const request = {
                [field.auto_select_associated_on_change[i]]: val || '',
                id: this.itemId,
              }

              if(this.$route?.name === 'Models')
                this.$root.$emit('CustomDataTab', request)
            }
          }
        }
      },

      findIndexWithAttr(array, attr, value) {
        for(let i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
            return i;
          }
        }
        return -1;
      },

        async triggerChange(value) {
          // get source name for association fields
          const request = {
            [this.itemAssociation?.name || this.itemName]: value,
            id: this.itemId
          }

          //save edit for Custom Data
          if(this.$route?.name === 'Models')
            await this.$root.$emit('CustomDataTab', request)

          //save edit for BroadcastUsersUsers
          if(this.$route?.name === 'BroadcastUsersUsers')
            await this.$root.$emit('BroadcastUsersUsers', request)
        },

      async triggerChangeOnBlur(event) {
        let value = event?.target?.value
        let originalValue = this.value

        if(value?.toString() !== originalValue?.toString()) {
          if(typeof this.value === 'number' && value) value = Number(value);

          // get source name for association fields
          const request = {
            [this.itemAssociation?.name || this.itemName]: value,
            id: this.itemId
          }

          //save edit for Custom Data
          if(this.$route?.name === 'Models')
            await this.$root.$emit('CustomDataTab', request)

          //save edit for BroadcastUsersUsers
          if(this.$route?.name === 'BroadcastUsersUsers')
            await this.$root.$emit('BroadcastUsersUsers', request)

          this.$refs.refInputValue.lazyValue = this.value;
        }
      },

        formatDate(isoTimeString, short = false, shortest = false) {
            if (!isoTimeString) return;
            const date = new Date(isoTimeString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            if (short) {
                return `${day}.${month}.${year.toString().slice(-2)} ${hours}:${minutes}`;
            } else if (shortest) {
                return `${day}.${month}.${year}`;
            } else {
                return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
            }
        },

        addHttpIfMissing(url) {
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                url = "http://" + url;
            }
            return url;
        },

        fieldIcon() {
            return this.itemClickAction === 'open' ? 'mdi-menu-open' : this.itemDisplayType === 'link' && this.value ? 'mdi-open-in-new' : '';
        },

      checkDateValidations(date, field) {
        let disabled = false

        if(
          field?.validate?.rules?.type === 'date'
          && !field?.validate?.rules?.ignore_in_table
        ) {
          const timeString = this.item_obj?.[field?.validate?.rules?.val_from_field];
          const now = timeString ? new Date(timeString) : new Date();
          const d = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0 , 0);
          const timestamp = d.getTime();
          const compare = this.$getComparator(field?.validate?.rules?.action)

          if(compare(date.getTime(), timestamp) ) disabled = true
        }

        return disabled
      },

      checkTimeValidations(date, field) {
        let disabled = false

        if(
          field?.validate?.rules?.type === 'time'
          && field?.validate?.rules?.action
          && !field?.validate?.rules?.ignore_in_table
        ) {
          let hours = 0, minutes = 0;
          const now = new Date();
          let timeString = this.item_obj?.[field?.validate?.rules?.val_from_field];

          if(timeString) {
            //fix if chosen date is not Today
            if(
              this.entity_fields?.[field?.validate?.rules?.val_from_field]
                ?.validate?.rules?.type === 'date'
            ) {
              if(this.isToday(timeString)) {
                timeString = `${now.getHours()}:${now.getMinutes()}`
              } else {
                timeString = '00:00'
              }
            }

            [hours, minutes] = timeString.split(':').map(Number);
          } else if(!field?.validate?.rules?.val_from_field) {
            hours = now.getHours();
            minutes = now.getMinutes();
          }

          const d = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
          const timestamp = d.getTime();
          const compare = this.$getComparator(field?.validate?.rules?.action)

          if(compare( timestamp, date.getTime() )) disabled = true
        }

        return disabled
      },

      checkClearableValidations(field) {
        let clearable = true

        if(field?.validate?.disable_clearable) {
          const required_field = field?.validate?.disable_clearable?.required_field

          if(
            required_field
            && this.item_obj?.[required_field]
          ) clearable = false
        }

        return clearable

      },

      //based on disable_clearable
      checkDisableValidations(field) {
        let disabled = false

        if(field?.validate?.required_field_empty) {
          //val from another field
          const val = this.item_obj[field?.validate?.required_field_empty]
          if(!val) disabled = true
        }
        if(field?.validate?.required_field_not_empty) {
          //val from another field
          const val = this.item_obj[field?.validate?.required_field_not_empty]
          if(val) disabled = true
        }

        if(
          field?.validate?.disabled_in_table_on_edit === true
          && this.value
        ) disabled = true

        return disabled
      },


      isToday(dateString) {
        const [day, month, year] = dateString.split('.').map(Number);
        const givenDate = new Date(year, month - 1, day);

        const today = new Date();
        return givenDate.getDate() === today.getDate() &&
          givenDate.getMonth() === today.getMonth() &&
          givenDate.getFullYear() === today.getFullYear();
      },
    }

}

</script>

<style scoped lang="scss">
    .v-input::v-deep {

        &:not(.non-editable) {
            transition: all 0.25s;
            border: 1px solid transparent;

            &:hover {
                background-color: var(--v-primaryLight-base);
            }

            &.v-input--is-focused {
                border-color: var(--v-blueHaze-base);
            }
        }

        &.is-link,
        &.is-open {
            .v-input__slot, input {
                cursor: pointer;
            }

            .v-input__icon {
                opacity: 0;
                transition: all 0.25s;
            }

            &:hover {
                .v-input__icon {
                    opacity: 1;
                }
            }
        }

        &.is-link {
            .v-icon {
                font-size: 22px;
            }
        }

    }

  .colored_select::v-deep {

    .v-list {
        padding: 0;
    }

    .v-list-item {
      padding: 0;

      & > span {
        padding: 0 16px;
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
      }
    }

    .v-list-item--link:before {
        opacity: 0 !important;
    }
  }

    .calendar-cell ::v-deep {
        table {
            border-top: 1px solid var(--v-blueHaze-base) !important;

            th {
                border-top: none;
            }
        }
    }
</style>