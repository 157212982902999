<template>
  <div>
    <v-navigation-drawer
      v-model="modal"
      class="cd_modal"
      absolute
      temporary
      right

      ref="drawerMainModal"
      :width="navigation.width"
    >
      {{/** MODAL CLOSE BTN **/}}
      <v-btn
        class="modal__close-btn"
        icon
        small
        absolute
        top
        left
        @click="modal = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      {{/** END MODAL CLOSE BTN **/}}

      {{/** MODAL MENU **/}}
      <v-menu
        v-if="!modal_create_mode"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            absolute
            small
            top
            right
            v-bind="attrs"
            v-on="on"
            class="modal__menu-btn"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="canSave"
            link
            @click="$emit('deleteItemDrawer')"
            style="display:flex; align-items:center;"
          >
            <v-icon size="14" class="mr-2 mb-1">$icondelete</v-icon>
            <v-list-item-title class="text-body-2" v-text="i18n.t('modules.customdata.entity.delete')" />
          </v-list-item>
        </v-list>
      </v-menu>
      {{/** END MODAL MENU **/}}

      <v-form ref="form" v-model="form_valid" class="settings-form">
        <v-card elevation="0">

          <v-card-title class="navigation-drawer__title">
            {{returnDrawerTitle()}}
          </v-card-title>

          <v-card-text v-if="modal">

            <v-container grid-list-md>
              <v-layout wrap>
                {{/** ENTITY FIELDS **/}}
                <template v-for="(field, key) in entity_fields">
                  <v-flex
                    xs12
                    :key="key"
                    class="settings-form__field"
                    :class="{
                      'settings-form__field--hidden': hiddenInCreatePopup(field)
                    }"
                  >

                    {{/* print custom fields */}}
                    <div
                      v-if="field.hasOwnProperty('association')"
                    >
                      <AssociationSelect
                        :entity_fields="entity_fields"
                        :headers="headers"
                        :key_name="key"
                        :field="field"
                        :label="`${returnFilterKeyOrLabel(key, field)}`"
                        :modal_create_mode="modal_create_mode"
                        :canSave="canSave"
                        :rules="getFieldRules(field.rules)"

                        :item_obj="item_obj"

                        @set_item_object_by_key="setItemObj($event)"
                        @delete_key_item_object="delete this.item_obj[$event]"
                      />
                    </div>
                    <template
                      v-if="field.hasOwnProperty('association')"
                    >
<!--                      <div>-->
<!--                        <v-autocomplete-->
<!--                          outlined-->
<!--                          dense-->
<!--                          :value="getItemObjValueForAssociation(key, field)"-->
<!--                          @input="setItemObjValueForAssociation(key, $event)"-->
<!--                          :label="`${returnFilterKeyOrLabel(key, field)}`"-->
<!--                          :item-text="(item) => item.label || item.name"-->
<!--                          item-value="id"-->
<!--                          :items="association_items"-->
<!--                          return-object-->
<!--                          :readonly="!modal_create_mode && !canSave"-->
<!--                          @change="fetchOnChangeField(field, $event)"-->
<!--                          :rules="getFieldRules(field.rules)"-->

<!--                          :loading="association_items_loading ? 'white' : false"-->
<!--                          hide-no-data-->
<!--                        />-->

<!--                        <DynamicEntityFieldsStatic-->
<!--                          v-if="-->
<!--                                  field.hasOwnProperty('fetch_on_create') &&-->
<!--                                  Object.keys(fetched_on_create_fields).length-->
<!--                                "-->
<!--                          :static_fields="fetched_on_create_fields"-->
<!--                          :item_obj="item_obj"-->
<!--                        />-->
<!--                      </div>-->
                    </template>

                    <template v-else-if="checkNewComponent(key) && !field.hidden_all">
                      <div class="d-flex align-center">
                        <!-- Custom Edits for Fields -->
                        <template
                          v-if="'edit_type' in field"
                        >
                          <template
                            v-if="
                              ['STRING'].includes(field.type)
                              && field['edit_type'] === 'file_upload'
                            "
                          >
                            <v-text-field
                              class="file_upload__string"
                              outlined
                              dense
                              v-model.trim="item_obj[key]"
                              :label="returnFilterKeyOrLabel(key, field)"
                              :rules="getFieldRules(field.rules)"
                              :readonly="!modal_create_mode && !canSave"
                            >
                              <template v-slot:append-outer>
                                <v-file-input
                                  class="ma-0 pa-0"
                                  @change="uploadFileToString($event, key)"
                                  accept="*/*"
                                  :hide-input="true"
                                  dense
                                ></v-file-input>

                              </template>
                            </v-text-field>
                          </template>
                        </template>
                        <!-- end Custom Edits for Fields -->

                        <!-- STRING FIELDS -->
                        <template v-else-if="['STRING'].includes(field.type)">

                          <template v-if="field.string_input">
                            <template
                              v-if="entity_fields[key].string_input.type === 'date'"
                            >
                              <date-picker
                                v-model.trim="item_obj[key]"
                                :type="field.string_input.type"
                                :valueType="field.string_input.format"
                                :format="field.string_input.format"
                                :show-second="false"
                                :placeholder="returnFilterKeyOrLabel(key, field)"
                                :lang="$i18n.locale"
                                :editable="!modal_create_mode && !canSave"
                                :append-to-body="false"

                                :disabled="checkDisableValidations(field)"
                                :disabled-date="(item) => checkDateValidations(item, field)"
                                :disabled-calendar-changer="(item) => checkDateValidations(item, field)"
                                :disabled-time="(item) => checkTimeValidations(item, field)"
                                :rules="getFieldRules(field.rules)"
                              />
                            </template>
                            <template
                              v-if="entity_fields[key].string_input.type === 'time'"
                            >
                              <TimeInput
                                v-model.trim="item_obj[key]"
                                :label="returnFilterKeyOrLabel(key, field)"

                                :field_key="key"
                                :item_obj="item_obj"
                                :entity_fields="entity_fields"
                                prepend-icon="mdi-clock-time-five-outline"
                              />
                            </template>
                          </template>

                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="getFieldRules(field.rules)"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-text-field>

                        </template>


                        {{/*TEXTAREA FIELDS*/}}
                        <v-textarea
                          outlined
                          dense
                          v-if="['TEXT'].includes(field.type)"
                          v-model.trim="item_obj[key]"
                          :label="returnFilterKeyOrLabel(key, field)"
                          :rules="getFieldRules(field.rules)"
                          auto-grow
                          rows="2"
                          :readonly="!modal_create_mode && !canSave"
                        ></v-textarea>

                        {{/*INTEGER FIELDS*/}}
                        <v-text-field
                          outlined
                          dense
                          v-if="['INTEGER', 'BIGINT'].includes(field.type)"
                          v-model.trim="item_obj[key]"
                          :label="returnFilterKeyOrLabel(key, field)"
                          :rules="[rules.onlyDigits, ...getFieldRules(field.rules)]"
                          :readonly="!modal_create_mode && !canSave"
                        ></v-text-field>

                        {{/*FLOAT FIELDS*/}}
                        <v-text-field
                          outlined
                          dense
                          v-if="['FLOAT'].includes(field.type)"
                          v-model.trim="item_obj[key]"
                          :label="returnFilterKeyOrLabel(key, field)"
                          :rules="[rules.floats, ...getFieldRules(field.rules)]"
                          :readonly="!modal_create_mode && !canSave"
                        ></v-text-field>

                        {{/*BOOL FIELDS*/}}
                        <v-checkbox
                          v-if="field.type === 'BOOLEAN'"
                          v-model="item_obj[key]"
                          :label="returnFilterKeyOrLabel(key, field)"
                          hide-details
                          :rules="getFieldRules(field.rules)"
                          class="mt-0 mb-4"
                          :readonly="!modal_create_mode && !canSave"
                        ></v-checkbox>

                        {{/*DATE FIELDS*/}}
                        <v-menu
                          v-if="['DATE'].includes(field.type)"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          :disabled="!modal_create_mode && !canSave"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              dense
                              :value="formatDate(item_obj[key], false, true)"
                              :label="returnFilterKeyOrLabel(key, field)"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              :rules="getFieldRules(field.rules)"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item_obj[key]"
                            no-title
                            :locale="i18n.locale"
                            scrollable
                          >
                          </v-date-picker>
                        </v-menu>

                        {{/*FILE FIELDS*/}}
                        <div class="custom-data__file" v-if="field.file_upload && item_obj.id">

                          <div class="custom-data__file--label">{{ returnFilterKeyOrLabel(key, field) }}</div>

                          <div class="d-flex align-center">
                            <div
                              class="d-flex align-center custom-data__file--item"
                              @click="openFileDialog($event)"
                            >

                              <template v-if="item_obj[key] && item_obj[key].files">
                                <a
                                  :href="file.url"
                                  target="_blank"
                                  v-for="(file, fileIndex) in item_obj[key].files"
                                  :key="fileIndex"
                                  class="file-item-link"
                                >
                                  <v-img
                                    v-if="isImage(file.name)"
                                    height="40"
                                    width="40"
                                    :src="file.url"
                                  ></v-img>

                                  <v-icon v-else size="50" color="black3">mdi-file</v-icon>

                                  <v-icon
                                    size="14"
                                    color="primary"
                                    @click.prevent.stop="deleteFile(file.name, item_obj.id, key)"
                                    class="file-item-del"
                                  >mdi-close-circle</v-icon>

                                </a>
                              </template>
                            </div>

                            <v-btn
                              elevation="0"
                              height="36"
                              width="36"
                              min-width="auto"
                              text
                              class="custom-table__file--btn px-0 order-1 ml-2"
                              @click="openFileDialog($event)"
                            >
                              <v-icon size="20" color="primary">mdi-plus-circle-outline</v-icon>
                            </v-btn>

                            <v-file-input
                              multiple
                              v-model="uploadFiles"
                              accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .docx, .xls, .xlsx"
                              :label="$t('modules.customdata.upload')"
                              v-on:change="item_obj.id ? uploadFile(item_obj.id, $event, key) : false"
                              class="fileInput"
                              style="display: none;"
                              :rules="getFieldRules(field.rules)"
                            ></v-file-input>

                          </div>
                        </div>

                        <template v-if="field.type_input">
                          {{field}}
                        </template>
                      </div>
                    </template>

                    <template v-else>
                      <template v-if="key == 'cd_map'">
                        <v-flex xs12>
                          <div class="navigation-drawer__item-title">{{ i18n.t('modules.customdata.entity.map') }}</div>
                          <div class="navigation-drawer__wrap">
                            <v-text-field
                              outlined
                              dense
                              label="Longitude"
                              :rules="getFieldRules(field.rules)"
                              v-model="item_obj[key + 'lon']"
                              @input="newComponentsMapChange(key)"
                              :hint="returnItemObjectValueJson(key, 'longitude')"
                            ></v-text-field>
                            <v-text-field
                              outlined
                              dense
                              label="Latitude"
                              :rules="getFieldRules(field.rules)"
                              v-model="item_obj[key + 'lat']"
                              @input="newComponentsMapChange(key)"
                              :hint="returnItemObjectValueJson(key, 'latitude')"
                            ></v-text-field>
                            <CdMap :mapObject="parseMapObject(item_obj[key])" />
                          </div>
                        </v-flex>
                      </template>
                      <template v-else-if="key == 'cd_chart'">
                        <v-flex xs12>
                          <div class="navigation-drawer__item-title">{{ i18n.t('modules.customdata.entity.chart') }}</div>
                          <div class="navigation-drawer__wrap">
                            <v-text-field
                              outlined
                              dense
                              :label="returnFilterKeyOrLabel(key, field)"
                              :rules="[rules.onlyDigits, ...getFieldRules(field.rules)]"
                              v-model="item_obj[key]"
                            ></v-text-field>
                            <CdChart :value="item_obj[key]" />
                          </div>
                        </v-flex>
                      </template>

                      <template v-if="key == 'cd_image_slider'">
                        <div class="navigation-drawer__wrap">
                          <!-- <v-text-field
                            outlined
                            dense
                            :label="returnFilterKeyOrLabel(key, field)"
                            v-model="item_obj[key]"
                          ></v-text-field> -->
                          <CdImageSlider :imageSliderObject="mockupForNewComponents.imageSlider" />
                        </div>
                      </template>

                      <template v-if="key == 'cd_image_list'">
                        <div class="navigation-drawer__wrap">
                          <CdImageList :imageListObject="mockupForNewComponents.imageList" />.
                        </div>
                      </template>

                      <template v-if="key == 'cd_chat'">
                        <div class="navigation-drawer__wrap">
                          <CdChat @send-message="sendChatMessage(key, $event), save(_, true)" :chatObject="parseChatObject(item_obj[key])" />
                        </div>
                      </template>

                    </template>

                  </v-flex>

                </template>

                <!-- Collecting fields  -->
                <template
                  v-if="
                    modal
                    && entitySubtypeItems(entity_fields, 'show_image_in_slider').length
                  "
                >
                  <v-flex xs12 >
                    <div class="navigation-drawer__wrap">
                      <vue-scroll>
                        <div class="cd__image-slider">
                          <template
                            v-for="
                                    (field, key) in entitySubtypeItems(entity_fields, 'show_image_in_slider')
                                  "
                          >
                            <CdImageSliderItem
                              :imageUrl="item_obj[field]"
                              :key="key"
                            />
                          </template>
                        </div>
                      </vue-scroll>
                    </div>
                  </v-flex>
                </template>
                <!-- end Collecting fields -->

                <!-- STATIC Collecting fields from association by attributes array FOR SLIDER -->
                <template
                  v-if="
                          entityAssociatonAttributesSubtypeItems('show_image_in_slider').length
                        "
                >
                  <v-flex xs12 >
                    <div class="navigation-drawer__wrap">
                      <vue-scroll>
                        <div class="cd__image-slider">
                          <template
                            v-for="
                                    (field, key) in entityAssociatonAttributesSubtypeItems('show_image_in_slider')
                                  "
                          >
                            <CdImageSliderItem
                              :imageUrl="item_obj[field]"
                              :key="key"
                            />
                          </template>
                        </div>
                      </vue-scroll>
                    </div>
                  </v-flex>
                </template>
                <!-- end STATIC Collecting fields from association by attributes array FOR SLIDER -->


                <!-- STATIC Collecting fields from association by attributes array FOR MAP-->
                <template
                  v-if="
                          entityAssociatonAttributesSubtypeItems('show_in_map').length
                        "
                >
                  <v-flex xs12>
                    <div class="navigation-drawer__item-title">{{ i18n.t('modules.customdata.entity.map') }}</div>
                    <div class="navigation-drawer__wrap">
                      <template
                        v-for="(field, key) in entityAssociatonAttributesSubtypeItems('show_in_map')"
                      >
                        <v-text-field
                          :key="key"
                          outlined
                          dense
                          label="Longitude"
                          :rules="getFieldRules(field.rules)"
                          v-model="item_obj[field]"
                        ></v-text-field>
                      </template>

                      <CdMap
                        :mapObject="parseMapObject(JSON.stringify(
                                {
                                  'longitude': item_obj['longitude'],
                                  'latitude': item_obj['latitude'],
                                }
                              ))"
                      />
                    </div>
                  </v-flex>
                </template>
                <!-- end STATIC Collecting fields from association by attributes array FOR MAP-->

              </v-layout>
            </v-container>

          </v-card-text>

          <v-footer>
            <v-layout justify-start>
              <v-btn
                class="mr-4"
                color="primary"
                elevation="0"
                @click="$emit('save')"
                small
                :disabled="!form_valid && !modal_create_mode && !canSave"
              >
                <template v-if="modal_create_mode">{{i18n.t('common.create')}}</template>
                <template v-else>{{i18n.t('common.save')}}</template>

              </v-btn>
            </v-layout>
          </v-footer>

        </v-card>
      </v-form>

    </v-navigation-drawer>
  </div>

</template>

<script>
import i18n from "@/lang/i18n";
import {mapGetters} from "vuex";
import CdMap from "@/modules/CustomData/views/customdata/NewComponents/CdMap.vue";
import CdImageSliderItem from "@/modules/CustomData/views/customdata/NewComponents/CdImageSliderItem.vue";
import CdChart from "@/modules/CustomData/views/customdata/NewComponents/CdChart.vue";
import CdImageSlider from "@/modules/CustomData/views/customdata/NewComponents/CdImageSlider.vue";
// import DynamicEntityFieldsStatic from "@/modules/CustomData/components/DynamicEntityFieldsStatic.vue";
import CdImageList from "@/modules/CustomData/views/customdata/NewComponents/CdImageList.vue";
import CdChat from "@/modules/CustomData/views/customdata/NewComponents/CdChat.vue";
import Vue from "vue";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';
import AssociationSelect from "@/modules/CustomData/components/modalEntityFields/AssociationSelect.vue";
import TimeInput from "@/components/dynamic/TimeInput.vue";

export default {
  name: "DynamicModalEntity",
  components: {
    TimeInput,
    AssociationSelect,
    CdChat, CdImageList,
    // DynamicEntityFieldsStatic,
    CdImageSlider,
    CdChart,
    CdImageSliderItem,
    CdMap,
    DatePicker,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modal_create_mode: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },


    entity_fields: {
      type: Object,
      default: () => {},
    },
    item_obj: {
      type: Object,
      default: () => {},
    },

    multiple_entity: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },

    custom_drawer_title: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    i18n,
    form_valid: true,

    //Panel resize
    navigation: {
      width: localStorage.getItem("drawerModalWidth") || '50%',
      borderSize: 8
    },
    //Panel resize

    // association_items_loaded: false,
    // association_items_loading: false,
    // association_items: [],

    // create_association_row_dialog: false,

    // fetched_on_create_fields: {},
    uploadFiles: [],

    mockupForNewComponents: {
      map: {
        latitude: 46.70,
        longitude: 32.60,
        apiKey: ''
      },
      imageSlider: [
        {
          id: 1,
          url: 'https://media.istockphoto.com/id/1154103408/pl/zdj%C4%99cie/facet-dostawy-rower%C3%B3w.jpg?s=2048x2048&w=is&k=20&c=OqpwBoYMbVeJYutsMACeIzbaD6c1Zj68jC3Hwyw5dXk=',
        },
        {
          id: 2,
          url: 'https://media.istockphoto.com/id/1159164272/pl/zdj%C4%99cie/przygotowanie-ciasta.jpg?s=2048x2048&w=is&k=20&c=Ki6F_1lw13qjnhYAoGuwsYAZ4Gv77ns0H4jAPlIckXY=',
        },
        {
          id: 3,
          url: 'https://media.istockphoto.com/id/1149169860/pl/zdj%C4%99cie/inteligentny-telefon-z-finansami-na-stole.jpg?s=1024x1024&w=is&k=20&c=DKgwfO_kz13jj4xIYYmEc-r-a9sf2u5qzzMVDRVO1-M=',
        },
        {
          id: 4,
          url: 'https://media.istockphoto.com/id/1129381764/pl/zdj%C4%99cie/sma%C5%BCenie-jajka-na-patelni-w-kuchni-domowej.jpg?s=2048x2048&w=is&k=20&c=7HraMxYiUtY8p8VhRb5QuWUxa3yo_2pDInuHfd-qjlE=',
        },
        {
          id: 5,
          url: 'https://media.istockphoto.com/id/1147289749/pl/zdj%C4%99cie/przyprawy-przyczyniaj%C4%85-si%C4%99-bogaty-smak-do-%C5%BCywno%C5%9Bci-bez-dodawania-kalorii.jpg?s=2048x2048&w=is&k=20&c=Uc-3_FCDDzE3CUJOg3HYtapRo51fAOOs4URuZW_QYZU=',
        },
      ],
      imageList: [
        {
          id: 1,
          url: 'https://media.istockphoto.com/id/1154103408/pl/zdj%C4%99cie/facet-dostawy-rower%C3%B3w.jpg?s=2048x2048&w=is&k=20&c=OqpwBoYMbVeJYutsMACeIzbaD6c1Zj68jC3Hwyw5dXk=',
        },
        {
          id: 2,
          url: 'https://media.istockphoto.com/id/1159164272/pl/zdj%C4%99cie/przygotowanie-ciasta.jpg?s=2048x2048&w=is&k=20&c=Ki6F_1lw13qjnhYAoGuwsYAZ4Gv77ns0H4jAPlIckXY=',
        },
        {
          id: 3,
          url: 'https://media.istockphoto.com/id/1149169860/pl/zdj%C4%99cie/inteligentny-telefon-z-finansami-na-stole.jpg?s=1024x1024&w=is&k=20&c=DKgwfO_kz13jj4xIYYmEc-r-a9sf2u5qzzMVDRVO1-M=',
        },
        {
          id: 4,
          url: 'https://media.istockphoto.com/id/1129381764/pl/zdj%C4%99cie/sma%C5%BCenie-jajka-na-patelni-w-kuchni-domowej.jpg?s=2048x2048&w=is&k=20&c=7HraMxYiUtY8p8VhRb5QuWUxa3yo_2pDInuHfd-qjlE=',
        },
        {
          id: 5,
          url: 'https://media.istockphoto.com/id/1147289749/pl/zdj%C4%99cie/przyprawy-przyczyniaj%C4%85-si%C4%99-bogaty-smak-do-%C5%BCywno%C5%9Bci-bez-dodawania-kalorii.jpg?s=2048x2048&w=is&k=20&c=Uc-3_FCDDzE3CUJOg3HYtapRo51fAOOs4URuZW_QYZU=',
        },
      ],
      chart: 91,
      chat: [
        {
          id: 1,
          userName: 'feijie',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 2,
          userName: 'okomkok',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 3,
          userName: 'feijie',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 4,
          userName: 'okomkok',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 5,
          userName: 'feijie',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 6,
          userName: 'okomkok',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 7,
          userName: 'feijie',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
        {
          id: 8,
          userName: 'okomkok',
          message: 'fwekjbfkjwen efwew f jknkjnkjfew'
        },
      ]
    },
  }),

  mounted() {
    //Panel resize
    this.setBorderWidth();
    this.setEvents();
    //Panel resize
  },

  beforeDestroy: function () {
    this.setEvents(true); // Pass true to remove EventListeners
  },

  computed: {
    ...mapGetters([
      'get_custom_data_front_json',
      'custom_data_linked_tables_values',
      'rules',
      'engine_settings',
      'bot_settings'
    ]),

    modal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },

  methods: {

    returnDrawerTitle() {
      if(this.custom_drawer_title) {
        return this.custom_drawer_title
      } else if(this.modal_create_mode) {
        this.$t('modules.customdata.entity.modal_title_create')
      } else {
        this.$t('modules.customdata.entity.modal_title')
      }
    },

    //Panel resize
    setBorderWidth() {
      let i = this.$refs.drawerMainModal.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },

    setEvents(clean = false) {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawerMainModal.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
          ? window.innerWidth - e.clientX
          : e.clientX;
        el.style.width = f + "px";
      }

      if (clean) {
        drawerBorder.removeEventListener("mousedown", this.handleMouseDown, false);
        document.removeEventListener("mouseup", this.handleMouseUp, false);
      } else {
        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              el.style.transition ='initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );

        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition ='';

            if(window.innerWidth < 960){
              vm.navigation.width = parseInt(window.innerWidth);
            } else {
              vm.navigation.width = el.style.width;
            }

            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);

            localStorage.setItem("drawerModalWidth", vm.navigation.width);
          },
          false
        );
      }
    },
    //Panel resize end


    //fields
    hiddenInCreatePopup(field) {
      let hidden = false
      if (this.modal_create_mode) {
        if (field?.hidden_in_create_popup) {
          // console.log(field, 'hiddenInCreatePopup')
          hidden = true
        }
      }
      return hidden
    },

    // getItemObjValueForAssociation(key, field) {
    //
    //   //auto select first on created mode
    //   if(field?.auto_select_first && this.modal_create_mode){
    //     this.setItemObjValueForAssociation(
    //       key,
    //       this.custom_data_linked_tables_values[key][0]
    //     )
    //
    //     return this.item_obj[key]
    //   }
    //
    //   let item =  this.custom_data_linked_tables_values[key].filter((i) => {
    //     if (i.name === this.item_obj[key]){
    //       this.item_obj[key] = JSON.parse(JSON.stringify(i))
    //       return i
    //     } else if (i.name === this.item_obj[key]?.name) {
    //       return this.item_obj[key]
    //     }
    //   })
    //
    //   return item[0]
    // },

    // async fetchOnChangeField(field, value) {
    //   if(!this.modal_create_mode) return
    //
    //   //auto_select_associated_on_change
    //   if(field?.auto_select_associated_on_change?.length){
    //     for(const i in field?.auto_select_associated_on_change){
    //       if(
    //         this.custom_data_linked_tables_values[field.auto_select_associated_on_change[i]]
    //       ) {
    //         const val = this.custom_data_linked_tables_values[field.auto_select_associated_on_change[i]]
    //           .find((item) => item?.is_id === value?.is_id)?.name
    //
    //         this.setItemObjValueForAssociation(
    //           field.auto_select_associated_on_change[i],
    //           val || '',
    //         )
    //       }
    //     }
    //   }
    //
    //   //fetch data and fill/show static fields
    //   if(
    //     field?.association?.model_name &&
    //     field?.fetch_on_create?.length &&
    //     value?.id
    //   ) {
    //     this.fetched_on_create_fields = {}
    //
    //     const token = Vue.$cookies.get('token_e')
    //     const engine_url = this.engine_settings.active_url;
    //     const model_name = field?.association?.model_name
    //     const model_field = field?.association?.model_field
    //     const url = `${engine_url}/kw/custom_modules/${model_name}/list`
    //     const params = {
    //       limit: 1,
    //       offset: 0,
    //       order: {id: "desc"},
    //       filter: {[model_field]: {eq: value.id}}
    //     }
    //
    //     await axios({
    //       method: 'POST',
    //       headers: {
    //         'X-CSRF-TOKEN': token,
    //       },
    //       url: url,
    //       params: {
    //         bot_id: this.$route?.params?.id,
    //       },
    //       data: params,
    //     })
    //       .then( (response) => {
    //           const columns = response.data.data.columns;
    //           const data = response.data.data.rows;
    //
    //           if(data?.length) {
    //             for(const i in field?.fetch_on_create) {
    //               const fieldName = field?.fetch_on_create[i]
    //               //check if field exist in headers
    //               const objFromHeader = this.headers?.find((el) => el.name === fieldName);
    //
    //               if(objFromHeader) {
    //                 Vue.set(
    //                   this.item_obj,
    //                   fieldName,
    //                   data[0]?.[fieldName]
    //                 );
    //
    //                 Vue.set(
    //                   this.fetched_on_create_fields,
    //                   fieldName,
    //                   {
    //                     label: columns.find( (el) => el.name === fieldName )?.label || fieldName,
    //                     subtype: columns.find( (el) => el.name === fieldName )?.subtype || 'STRING'
    //                   }
    //                 )
    //               }
    //             }
    //           }
    //         },
    //         (err) => {
    //           console.log(err, 'err')
    //         }
    //       );
    //   }
    // },
    // findIndexWithAttr(array, attr, value) {
    //   for(let i = 0; i < array.length; i += 1) {
    //     if(array[i][attr] === value) {
    //       return i;
    //     }
    //   }
    //   return -1;
    // },

    // setItemObjValueForAssociation(key, value) {
    //   (value) ? this.item_obj[key] = value : delete this.item_obj[key]
    // },

    returnFilterKeyOrLabel(key, item) {
      return item?.label ? item?.label : key
    },

    checkNewComponent(key) {
      const newComponentsList = ['cd_map', 'cd_chart', 'cd_chat', 'cd_image_slider', 'cd_image_list']
      const matchResult = newComponentsList.some(newComponentsList => newComponentsList === key)
      return !matchResult
    },

    formatDate(isoTimeString, short = false, shortest = false) {
      if (!isoTimeString) return;
      const date = new Date(isoTimeString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      if (short) {
        return `${day}.${month}.${year.toString().slice(-2)} ${hours}:${minutes}`;
      } else if (shortest) {
        return `${day}.${month}.${year}`;
      } else {
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      }
    },

    openFileDialog(event) {
      const previewLink = event.target.closest('a.file-item-link');
      if (!previewLink) {
        event.target.closest('.custom-data__file').querySelector('.fileInput label').click();
      }
    },

    /**
     * Delete file
     * @method deleteFile
     *
     * */
    async deleteFile(file_name, id, field_name) {

      if (!file_name) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete_file/${id}`;

      const request = {
        field_name: field_name,
        file_name: file_name
      };

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'application/json',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
        .then( async (response) => {
            this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.deleted') ]);

            if ( response?.data?.status === 'success' ) {
              const updatedFiles = this.item_obj[field_name].files.filter(file => file.name !== file_name);
              Vue.set(this.item_obj[field_name], 'files', updatedFiles);
              this.getItems();
              success = true;
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
          }
        );

      return success;
    },

    /**
     * Upload file
     * @method uploadFile
     *
     * */
    async uploadFile(id, data, field_name) {

      if (data.length === 0) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update_file/${id}`;
      const request = new FormData();

      let i = 0;
      for (let file of data) {
        request.set('files[' + i + ']', file, file.name);
        i++;
      }

      request.append('field_name', field_name);

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
        .then( async (response) => {
            this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.uploaded') ]);

            if ( response?.data?.status === 'success' ) {
              this.item_obj[field_name] = {files: []}
              const updatedFiles = response.data.data[field_name].files;
              this.item_obj[field_name].files = updatedFiles;
              this.getItems();
              this.uploadFiles = [];
              success = true;
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
          }
        );

      return success;
    },

    uploadFileToString(event, key) {

      let data = new FormData();
      data.set('files[' + 0 + ']', event, event.name);
      let url = this.engine_settings.active_url;
      let token = Vue.$cookies.get('token_e');
      axios
        .post(url + '/kw/filemanager/files/upload', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          async (response) => {
            if(response?.data?.status === 'success' && response?.data?.data[0]) {
              Vue.set(
                this.item_obj,
                key,
                response?.data?.data?.[0]
              );
            }
          },
          (err) => {
            this.saving = false;
            this.$store.dispatch('updateAjaxDialogError', [
              err,
              this.$t('common.error'),
              this.$t('common.error_get'),
            ]);
          }
        );

    },

    returnItemObjectValueJson(key, name){
      let value = null
      if(this.item_obj?.[key]) {
        let convertToObj = null
        try {
          convertToObj = JSON.parse(this.item_obj?.[key])
        } catch (e) {
          // console.log(e, 'JSON.parse(this.item_obj?.[key])')
        }
        value = convertToObj ? convertToObj[name] : null
      }
      return value

    },

    isImage(file) {
      const fileName = file.toLowerCase();
      return (fileName.endsWith('.jpg') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.png') ||
        fileName.endsWith('.gif') ||
        fileName.endsWith('.bmp') ||
        fileName.endsWith('.svg'));
    },

    entitySubtypeItems(entities, subtype) {
      const items = []

      if(this._hasObjectWithCustomValue(entities, 'subtype', subtype)) {
        for(let item in entities){
          if(
            entities[item]?.['subtype'] === subtype
            && this.item_obj[item]
          ) items.push(item)
        }
      }

      return items
    },
    _hasObjectWithCustomValue(obj, keyName, valueToCheck) {
      for (let key in obj) {
        if (
          obj.hasOwnProperty(key)
          && typeof obj[key] === 'object'
          && obj[key].hasOwnProperty(keyName)
          && obj[key][keyName] === valueToCheck
        ) return true
      }
      return false;
    },

    entityAssociatonAttributesSubtypeItems(subtype) {
      const items = []
      const attrKeys = this._returnAssociationAttributesKeys(subtype)
      if(!attrKeys?.length) return items

      for(let item in this.item_obj) {
        if(
          attrKeys.indexOf(item) !== -1
          && this.item_obj[item]
        ) items.push(item)
      }

      return items
    },
    _returnAssociationAttributesKeys(subtypeValue) {
      const keysFromAttributes = []
      if(!this.modal) return keysFromAttributes

      for (let item in this.entity_fields) {
        if(
          this.entity_fields[item]?.association?.attributes?.length
          && this.entity_fields[item]?.association?.subtype === subtypeValue
        ) {
          const attrArray = this.entity_fields[item].association.attributes
          for (let attr in attrArray) {
            if(
              keysFromAttributes.indexOf(attrArray[attr]) === -1
              && item !== attrArray[attr]
            ) {
              keysFromAttributes.push(attrArray[attr])
            }
          }
        }
      }

      return keysFromAttributes
    },

    checkDisableValidations(field) {
      let disabled = false

      if(field?.validate?.required_field_empty) {
        //val from another field
        const val = this.item_obj[field?.validate?.required_field_empty]
        if(!val) disabled = true
      }
      if(field?.validate?.required_field_not_empty) {
        //val from another field
        const val = this.item_obj[field?.validate?.required_field_not_empty]
        if(val) disabled = true
      }

      return disabled
    },

    checkDateValidations(date, field) {
      let disabled = false

      if( field?.validate?.rules?.type === 'date' ) {
        const timeString = this.item_obj[field?.validate?.rules?.val_from_field];
        const now = timeString ? new Date(timeString) : new Date();
        const d = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0 , 0);
        const timestamp = d.getTime();
        const compare = this.$getComparator(field?.validate?.rules?.action)

        if(compare(date.getTime(), timestamp) ) disabled = true
      }

      return disabled
    },

    checkTimeValidations(date, field) {
      let disabled = false

      if(
        field?.validate?.rules?.type === 'time'
        && field?.validate?.rules?.action
      ) {
        let hours = 0, minutes = 0;
        const now = new Date();
        let timeString = this.item_obj[field?.validate?.rules?.val_from_field];

        if(timeString) {
          //fix if chosen date is not Today
          if(
            this.entity_fields?.[field?.validate?.rules?.val_from_field]
            ?.validate?.rules?.type === 'date'
          ) {
            if(this.isToday(timeString)) {
              timeString = `${now.getHours()}:${now.getMinutes()}`
            } else {
              timeString = '00:00'
            }
          }

          [hours, minutes] = timeString.split(':').map(Number);
        } else if(!field?.validate?.rules?.val_from_field) {
          hours = now.getHours();
          minutes = now.getMinutes();
        }

        const d = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
        const timestamp = d.getTime();
        const compare = this.$getComparator(field?.validate?.rules?.action)

        if(compare( timestamp, date.getTime() )) disabled = true
      }

      return disabled
    },

    isToday(dateString) {
      const [day, month, year] = dateString.split('.').map(Number);
      const givenDate = new Date(year, month - 1, day);

      const today = new Date();
      return givenDate.getDate() === today.getDate() &&
        givenDate.getMonth() === today.getMonth() &&
        givenDate.getFullYear() === today.getFullYear();
    },

    getFieldRules(arr) {
      const rules = []
      if (arr && arr?.length) {
        for(let i in arr) {
          if( this.rules?.[arr[i]] ) {
            rules.push(this.rules[arr[i]])
          } else {
            //custom filter
            if(arr[i].indexOf('custom_') !== -1) {
              let rule = arr[i].split('_')?.[1]

              switch(rule) {
                case 'maxLimit':
                  // eslint-disable-next-line no-case-declarations
                  const maxNumber = Number(arr[i].split('_')?.[2])
                  // eslint-disable-next-line no-case-declarations
                  const maxLimit = (v) => v <= maxNumber || i18n.t("rules.maxLimit_") + maxNumber
                  rules.push(maxLimit)
                  break;
                case 'minLimit':
                  // eslint-disable-next-line no-case-declarations
                  const minNumber = Number(arr[i].split('_')?.[2])
                  // eslint-disable-next-line no-case-declarations
                  const minLimit = (v) => v > minNumber || i18n.t("rules.minLimit_") + minNumber
                  rules.push(minLimit)
                  break;
                default:
                  break;
              }
            }
          }
        }
      }

      return rules
    },

    setItemObj(data) {
      const {key, value} = data

      this.$set(
        this.item_obj,
        key,
        value
      )
    },

  }
}

</script>

<style lang="scss" scoped>
.settings-form::v-deep {
  height: 100%;

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text{
      padding-top: 5px;
      flex-grow: 1;
      overflow: auto;
    }
  }

  .mx-datepicker {
    width: 100%;
    margin-bottom: 20px;

    .mx-datepicker-main.mx-datepicker-popup {
      left: 0!important;
    }
    .mx-input {
      height: 44px;
      border-color: #9e9e9e;
    }
  }

  .settings-form__field {
    padding: 0 !important;

    &--hidden {
      display: none !important;
    }
  }
}

.modal__close-btn {
  z-index: 1;
  top: 9px;
}

.modal__menu-btn {
  z-index: 1;
  top: 18px;

  &::before {
    opacity: 0.08;
  }
}


.v-navigation-drawer {
  &::v-deep .v-navigation-drawer__border {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.08) !important;

    &:after {
      content: '·······';
      color: #000;
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      left: -14px;
      font-size: 24px;
      line-height: 0.3;
      pointer-events: none;
    }

    @media screen and (max-width: 959px){
      display: none;
    }
  }
}
.navigation-drawer {
  &__title {
    font-size: 18px;
    margin-top: 30px;
    padding-left: 27px;
    padding-right: 50px;
    padding-bottom: 10px;
    text-align: left;
  }

  &__wrap {
    padding: 20px 15px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    margin-bottom: 25px;

    .cd__image-slider {
      display: flex !important;
      gap: 16px !important;
      height: 400px !important;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  }
}

.file_upload {
  &__string {
    &::v-deep {
      .v-input__append-outer {
        margin-top: 0 !important;
      }
    }
  }
}

</style>