<template>
  <v-expansion-panels>
    <template v-for="(f, key) in field">
      <v-expansion-panel
        v-if="checkSomeKeyNotExists(f)"
        :key="key"
      >
        <v-expansion-panel-header
          :color="f.header_color ? f.header_color : ''"
        >
          <v-layout :align-center="true">
            <v-icon
              v-if="f.icon"
              :color="f.icon_color"
              class="mr-2"
            >{{f.icon}}</v-icon>

            {{returnFilterKeyOrLabel(null, f)}}
          </v-layout>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <template
                v-for="(item, key) in items(f)"
              >
                <tr
                  v-if="checkKeyNotExists(key, item)"
                  :key="key"
                >
                  <td>
                    <v-btn
                      v-if="item.editable"
                      small
                      icon
                      @click="item.edit_toggle = !item.edit_toggle"
                      color="green"
                      class="mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>

                    <v-icon
                      v-if="item.icon"
                      :color="item.icon_color"
                      class="mr-2"
                    >{{item.icon}}</v-icon>


                    {{ returnLabelFromColumn(key, item) }}
                  </td>
                  <td>
                    <v-layout
                      :align-center="true"
                    >
                      <div
                        class="my-1"
                        v-show="item.edit_toggle"
                      >
                        <template
                          v-if="item.editable"
                        >
                          <!--STRING FIELDS-->
                          <v-text-field
                            v-if="['STRING'].includes(item.type)"
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            @input="$emit('edit_obj', $event)"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                            background-color="white"
                          />

                          <!--TEXTAREA FIELDS-->
                          <v-textarea
                            v-else-if="['TEXT'].includes(item.type)"
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            @input="$emit('edit_obj', $event)"
                            auto-grow
                            rows="2"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                            background-color="white"
                          />

                          <!-- INTEGER FIELDS -->
                          <v-text-field
                            v-else-if="['INTEGER', 'BIGINT'].includes(item.type)"
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            @input="$emit('edit_obj', $event)"
                            :rules="[rules.onlyDigits]"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                            background-color="white"
                          ></v-text-field>

                          <!-- BOOL FIELDS -->
                          <v-checkbox
                            v-else-if="['BOOLEAN'].includes(item.type)"
                            v-model="item_obj[key]"
                            @input="$emit('edit_obj', $event)"
                            class="mt-0 mb-4"
                            :readonly="!modal_create_mode && !canSave"
                            hide-details="auto"
                          ></v-checkbox>

                        </template>
                      </div>

                      <div
                        v-show="!item.edit_toggle"
                      >
                        {{ item_obj[key] }}
                      </div>
                    </v-layout>

                  </td>
                </tr>
              </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>

</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'ExpansionPanelField',

  props: {
    modal_create_mode: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },


    field: {
      type: Array,
      default: () => [],
    },
    entity_fields: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => [],
    },
    item_obj: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    ...mapGetters([
      'rules',
    ]),
  },

  methods: {
    items(field) {

      //used for find keys by regex (wider in ExpansionPanelVariableField.vue)
      if(
        'findAs' in field
        && field?.findAs?.length
      ) {
        //'^supervisor_.+$';
        let regexStr = field?.findAs;
        let regex = new RegExp(regexStr);

        return Object.keys(this.entity_fields)
          .filter(key => regex.test(key))
          .reduce((obj, key) => {
            obj[key] = {
              type: this.entity_fields[key].type,
              label: this.entity_fields[key]?.label || key
            };
            return obj;
          }, {});
      }

      return field.items
    },


    checkKeyNotExists(key, field) {
      let show = true

      if(
        field?.hide_key_not_exists
        && !(key in this.item_obj)
      ) show = false

      return show
    },
    checkSomeKeyNotExists(f) {
      let show = true

      if(f?.hide_some_key_not_exists) {
        const items = Object.keys(f.items).filter((i) => {
          if(this.checkKeyNotExists(i, f.items[i])) {
            return i
          }
        })

        if(!items?.length) show = false
      }

      return show
    },
    returnLabelFromColumn(key, item = null) {
      let label = this.entity_fields[key]?.label || key
      let label_from_column_name = this.entity_fields?.[item?.label_from_column_name]?.label

      //get label from headers(columns in list)
      if(item?.label_from_column_name) {
        label_from_column_name =  this.headers?.find((i) => i.value === item.label_from_column_name )?.text
      }

      return item?.label || label_from_column_name || label
    },

    returnFilterKeyOrLabel(key, item) {
      const label_from_column_name = this.entity_fields?.[item?.label_from_column_name]?.label

      return item?.label || label_from_column_name || key
    },
  },

}

</script>