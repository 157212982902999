<template>
  <v-skeleton-loader v-if="!loaded" type="text" width="100%" height="40" />

  <div v-else>

    <v-autocomplete
      outlined
      dense
      v-model="value"

      :label="label"
      :item-text="(item) => item.label || item.name"
      item-value="id"
      return-object
      :items="association_items"
      :readonly="!modal_create_mode && !canSave"
      @change="fetchOnChangeField(field, $event)"
      :rules="rules"

      @focus="fetchAssociationItems(key_name, field)"
      :loading="association_items_loading ? 'white' : false"
      hide-no-data
    />

    <DynamicEntityFieldsStatic
      v-if="
        field.hasOwnProperty('fetch_on_create') &&
        Object.keys(fetched_on_create_fields).length
      "
      :static_fields="fetched_on_create_fields"
      :item_obj="item_obj"
    />
  </div>
</template>

<script>

import DynamicEntityFieldsStatic from "@/modules/CustomData/components/DynamicEntityFieldsStatic.vue";
import Vue from "vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'AssociationSelect',
  components: {
    DynamicEntityFieldsStatic
  },

  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    entity_fields: {
      type: Object,
      default: () => {},
    },

    key_name: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    modal_create_mode: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array,
      default: () => [],
    },

    item_obj: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    loaded: false,

    association_items_loading: false,
    // association_items: [],

    fetched_on_create_fields: {},
  }),

  async mounted() {
    //get list for auto select first on created mode
    if(this.field?.auto_select_first && this.modal_create_mode){
      await this.fetchAssociationItems(this.key_name, this.field)

      this.$nextTick(() => {
        this.setItemObjValueForAssociation(
          this.key_name,
          this.association_items?.[0] || {}
        )
      })
    }

    //get list for changed another fields
    if(this.field?.auto_select_associated_on_change?.length){
      // await this.fetchAssociationItems(this.key_name, this.field)

      for(const i in this.field?.auto_select_associated_on_change){
        await this.fetchAssociationItems(
          this.field.auto_select_associated_on_change[i],
          this.entity_fields?.[this.field.auto_select_associated_on_change[i]],
        )
      }
    }

    setTimeout(() => {
      this.loaded = true
    }, 1000)

  },

  computed: {
    ...mapGetters([
      'engine_settings',
      'custom_data_linked_tables_values',
      'custom_data_linked_tables_values_creation_modal',
    ]),

    association_items() {
      if(this.custom_data_linked_tables_values_creation_modal?.[this.key_name]?.length) {
        return this.custom_data_linked_tables_values_creation_modal[this.key_name]
      }

      if(this.custom_data_linked_tables_values?.[this.key_name]?.length) {
        return this.custom_data_linked_tables_values[this.key_name]
      }

      return []
    },

    value: {
      get() {
        // if(this.association_items?.length) {
        //   let item =  this.association_items?.filter((i) => {
        //     if (i.name === this.item_obj[this.key_name]){
        //       this.setItemObjValueForAssociation(
        //         this.key_name,
        //         JSON.parse(JSON.stringify(i)),
        //       )
        //       // this.item_obj[this.key_name] = JSON.parse(JSON.stringify(i))
        //       return i
        //     } else if (i.name === this.item_obj[this.key_name]?.name) {
        //       return this.item_obj[this.key_name]
        //     }
        //   })
        //
        //   return item[0]
        // }

        return this.item_obj[this.key_name] || {}
      },
      set(value) {
        (value)
          ? this.$emit('set_item_object_by_key', {
            key: this.key_name,
            value: value
          }) //this.item_obj[key] = value
          : this.$emit('delete_key_item_object', this.key_name) //delete this.item_obj[key]
      },
    },
  },

  methods: {
    async fetchAssociationItems(key, data) {
      if(
        this.custom_data_linked_tables_values_creation_modal?.[this.key_name]?.length
        || this.custom_data_linked_tables_values?.[this.key_name]?.length
      ) return

      this.association_items_loading = true

      await this.$store.dispatch('_customDataFetchGetLinkedTablesValues', {
        modelName: data.association.model_name,
        modelField: data.association?.model_field,
        linkedColumn: key,
        bot_id: this.$route?.params?.id,
        fetch_filter: data?.fetch_filter,
        modelFieldLabel: data.association?.model_field_label,
        fetch_limit: data?.association?.fetch_limit,
        get_full_data: data?.association?.get_full_data,
        params: {
          for_creation: key, //used in Action Jail
        }
      }).then(response => {
        this.$store.commit('CUSTOM_DATA_SET_LINKED_TABLE_VALUES_CREATION_MODAL', {
          column: key,
          values: response,
        })
      })

      this.association_items_loading = false
    },

    // getItemObjValueForAssociation(key, field) {
    //   //auto select first on created mode
    //   // if(field?.auto_select_first && this.modal_create_mode){
    //   //
    //   //   this.setItemObjValueForAssociation(
    //   //     key,
    //   //     this.association_items?.[0] || {}
    //   //   )
    //   //
    //   //   return this.item_obj[key]
    //   // }
    //
    //   let item =  this.association_items?.filter((i) => {
    //     if (i.name === this.item_obj[key]){
    //       this.item_obj[key] = JSON.parse(JSON.stringify(i))
    //       return i
    //     } else if (i.name === this.item_obj[key]?.name) {
    //       return this.item_obj[key]
    //     }
    //   })
    //
    //   return item[0]
    // },

    setItemObjValueForAssociation(key, value) {
      (value)
        ? this.$emit('set_item_object_by_key', {
          key: key,
          value: value
        }) //this.item_obj[key] = value
        : this.$emit('delete_key_item_object', key) //delete this.item_obj[key]
    },

    async fetchOnChangeField(field, value) {
      if(!this.modal_create_mode) return

      //auto_select_associated_on_change
      if(field?.auto_select_associated_on_change?.length){
        for(const i in field?.auto_select_associated_on_change){
          if(
            this.getAssociationItemsByKey(field.auto_select_associated_on_change[i])?.length
          ) {
            const val = this.getAssociationItemsByKey(field.auto_select_associated_on_change[i])
              .find((item) => item?.is_id === value?.is_id)

            if(val) {
              this.setItemObjValueForAssociation(
                field.auto_select_associated_on_change[i],
                val || '',
              )
            }

          }
        }
      }

      //fetch data and fill/show static fields
      if(
        field?.association?.model_name &&
        field?.fetch_on_create?.length &&
        value?.id
      ) {
        this.fetched_on_create_fields = {}

        const token = Vue.$cookies.get('token_e')
        const engine_url = this.engine_settings.active_url;
        const model_name = field?.association?.model_name
        const model_field = field?.association?.model_field
        const url = `${engine_url}/kw/custom_modules/${model_name}/list`
        const params = {
          limit: 1,
          offset: 0,
          order: {id: "desc"},
          filter: {[model_field]: {eq: value.id}}
        }

        await axios({
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': token,
          },
          url: url,
          params: {
            bot_id: this.$route?.params?.id,
          },
          data: params,
        })
          .then( (response) => {
              const columns = response.data.data.columns;
              const data = response.data.data.rows;

              if(data?.length) {
                for(const i in field?.fetch_on_create) {
                  const fieldName = field?.fetch_on_create[i]
                  //check if field exist in headers
                  const objFromHeader = this.headers?.find((el) => el.name === fieldName);

                  if(objFromHeader) {
                    this.$emit('set_item_object_by_key', {
                      key: fieldName,
                      value: data[0]?.[fieldName]
                    })

                    this.$set(
                      this.fetched_on_create_fields,
                      fieldName,
                      {
                        label: columns.find( (el) => el.name === fieldName )?.label || fieldName,
                        subtype: columns.find( (el) => el.name === fieldName )?.subtype || 'STRING'
                      }
                    )
                  }
                }
              }
            },
            (err) => {
              console.log(err, 'err')
            }
          );
      }
    },

    getAssociationItemsByKey(key) {
      if(this.custom_data_linked_tables_values_creation_modal?.[key]) {
        return this.custom_data_linked_tables_values_creation_modal[key]
      }

      if(this.custom_data_linked_tables_values?.[key]) {
        return this.custom_data_linked_tables_values[key]
      }

      return []
    },
  },
}

</script>