import Vue from "vue";
import axios from "axios";
import i18n from "@/lang/i18n.js";
import _ from "lodash";

const state = {
  custom_data:{
    tables:[],

    filters: [],
    filters_schema_json: {
      INTEGER: {
        type: 'INTEGER',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal//равно
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal//не равно

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than//больше
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than//меньше
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal//больше или равно
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal//меньше или равно

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пустое значение
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)//содержит
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]//в диапазоне
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]//не в диапазоне
        ]
      },
			BIGINT: {
				type: 'BIGINT',
				conditions: [
					{text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal//равно
					{text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal//не равно

					{text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than//больше
					{text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than//меньше
					{text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal//больше или равно
					{text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal//меньше или равно

					{text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пустое значение
					// {text:'like', value: 'like'},//Like search (%value, value%, %value%)//содержит
					// {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

					{text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]//в диапазоне
					{text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]//не в диапазоне
				]
			},
      FLOAT: {
        type: 'FLOAT',
        conditions: [
          // {text:'=', value: 'eq'},//Equal
          // {text:'!=', value: 'ne'},//Not equal

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]
        ]
      },

      STRING: {
        type: 'STRING',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      TEXT: {
        type: 'TEXT',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      BOOLEAN: {
        type: 'BOOLEAN',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.active"), value: 'active'},//Equal//да
          {text: i18n.t("modules.customdata.filters.filters_json.not_active"), value: 'notActive'},//Not equal//нет

          // {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пусто
        ]
      },
    },

    selected_filters: {},
    saved_filters: [],
    empty_selected_filters: {},
    linkedTablesValues: {},
    linkedTablesFullData: {},

		linkedTablesValuesCreationModal: {},

    toggle_active_filter: null,

    columns_schema_json: {
      name: '',
      active: true,
      position: 0,
      sort: null,
    },

    columns_data: [],
    columns_data_activated: [],
    saved_columns_data: [],
    default_columns_data: [],

		is_global_columns: false,

    groups: [],

		front_json: {},

		macros: [],
  }
};

const mutations = {
    CUSTOM_DATA_SET_TABLES(state, tables) {
    state.custom_data.tables = tables;
  },
  CUSTOM_DATA_SET_FILTERS(state, filters) {
    state.custom_data.filters = filters;
  },
  CUSTOM_DATA_SET_FILTERS_EMPTY(state) {
    state.custom_data.filters = []
  },
  CUSTOM_DATA_SET_LINKED_TABLE_VALUES(state, {column, values}) {
      Vue.set(state.custom_data.linkedTablesValues, column, values)
  },

	CUSTOM_DATA_SET_LINKED_TABLE_VALUES_CREATION_MODAL(state, {column, values}) {
		if(!state.custom_data.linkedTablesValuesCreationModal?.column?.length) {
			Vue.set(state.custom_data.linkedTablesValuesCreationModal, column, values)
		}
	},

  CUSTOM_DATA_CLEAR_LINKED_TABLE_VALUES(state) {
      state.custom_data.linkedTablesValues = {};
  },

	CUSTOM_DATA_SET_LINKED_TABLE_FULL_DATA(state, {column, values}) {
		Vue.set(state.custom_data.linkedTablesFullData, column, values)
	},

  CUSTOM_DATA_SET_EMPTY_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.custom_data.empty_selected_filters, key, values)
    Vue.set(state.custom_data.selected_filters, key, values)
  },

  CUSTOM_DATA_SET_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.custom_data.selected_filters, key, values)
  },

  CUSTOM_DATA_SET_SELECTED_FILTER_VALUE(state, {key, value}){
    Vue.set(state.custom_data.selected_filters[key], 'value', value)
  },
  CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION(state, {key, condition}){
    Vue.set(state.custom_data.selected_filters[key], 'condition', condition)
  },

  CUSTOM_DATA_DELETE_SELECTED_FILTER(state, key){
    Vue.delete(state.custom_data.selected_filters, key)
  },

  CUSTOM_DATA_DELETE_SELECTED_FILTER_IN_ARRAY(state, {key, value}) {
    let keyValueArray = state.custom_data.selected_filters[key]?.value

    if (Array.isArray(keyValueArray) && keyValueArray.length) {
      if (
        state.custom_data.selected_filters[key]?.condition?.value === 'between'
        || state.custom_data.selected_filters[key]?.condition?.value === 'notBetween'
        || state.custom_data.selected_filters[key]?.type === 'DATE'
      ) {
        Vue.set(state.custom_data.selected_filters[key], 'condition', null)
        Vue.set(state.custom_data.selected_filters[key], 'value', null)
      } else {
        let filteredArray = keyValueArray.filter(i => i !== value)
        if (!filteredArray.length) filteredArray = null
        Vue.set(state.custom_data.selected_filters[key], 'value', filteredArray)
        if (keyValueArray.length <= 1) {
          Vue.set(state.custom_data.selected_filters[key], 'condition', null)
        }
      }
    } else {
      Vue.set(state.custom_data.selected_filters[key], 'condition', null)
      Vue.set(state.custom_data.selected_filters[key], 'value', null)
    }
  },
  CUSTOM_DATA_SELECTED_FILTERS_EMPTY(state) {
    state.custom_data.selected_filters = {}
  },

  CUSTOM_DATA_SELECTED_FILTERS_CLEAR_VALUES(state) {
    Object.keys(state.custom_data.selected_filters)
      .map(function(key) {
        Vue.set(state.custom_data.selected_filters[key], 'condition', null)
        Vue.set(state.custom_data.selected_filters[key], 'value', null)
      })
  },

  CUSTOM_DATA_SET_SAVED_FILTERS(state, filters) {
    state.custom_data.saved_filters = filters;
  },
  CUSTOM_DATA_PUSH_SAVED_FILTERS(state, filter) {
    let filterExist = state.custom_data.saved_filters.find(item => item.id === filter.id)
    if (!filterExist) {
      state.custom_data.saved_filters.push(filter);
    } else {
      for (let i in state.custom_data.saved_filters) {
        if (state.custom_data.saved_filters[i].id === filter.id) {
          Vue.set(state.custom_data.saved_filters[i], 'filters_json', filter.filters_json)
        }
      }
    }
  },
  CUSTOM_DATA_DELETE_SAVED_FILTERS(state, name) {
    state.custom_data.saved_filters = state.custom_data.saved_filters.filter(item => item.name !== name);
  },

  CUSTOM_DATA_SET_COLUMNS_DATA(state, payload) {
    state.custom_data.columns_data = payload
  },
  CUSTOM_DATA_SET_COLUMNS_DATA_ACTIVATED(state, payload) {
    state.custom_data.columns_data_activated = payload
  },
  CUSTOM_DATA_TOGGLE_COLUMN_ACTIVE(state, {index, value}) {
		Vue.set(state.custom_data.columns_data[index], 'active', value)
			// console.log(state.custom_data.columns_data, 'state.custom_data.columns_data')
    // for (let i = 0; i < state.custom_data.columns_data?.length; i++) {
    //   if (state.custom_data.columns_data[i].name === name) {
    //     Vue.set(state.custom_data.columns_data[findWithAttr(state.custom_data.columns_data, 'name', name)], 'active', value)
    //   }
    // }
    state.custom_data.columns_data_activated = state.custom_data.columns_data
  },

  CUSTOM_DATA_CHANGE_ORDER_COLUMN(state, data) {
    const {oldIndex, newIndex} = data
		let columns = _.cloneDeep(state.custom_data.columns_data)
		columns = _.orderBy(columns, 'position')
		columns.splice(newIndex, 0, columns.splice(oldIndex, 1)[0])

    for (let i in state.custom_data.columns_data) {
			const newIndex = columns.findIndex((item) => item?.value === state.custom_data.columns_data[i]?.value)
      Vue.set(state.custom_data.columns_data[i], 'position', newIndex)
    }

    state.custom_data.columns_data_activated = state.custom_data.columns_data
  },

  CUSTOM_DATA_SET_SAVED_COLUMNS_DATA(state, payload) {
    Vue.set(state.custom_data, 'saved_columns_data', _.cloneDeep(payload))
    // state.custom_data.saved_columns_data = payload
  },
  CUSTOM_DATA_SET_DEFAULT_COLUMNS_DATA(state, payload) {
    state.custom_data.default_columns_data = payload
  },

  CUSTOM_DATA_SET_TOGGLE_ACTIVE_FILTER(state, value) {
    state.custom_data.toggle_active_filter = value
  },

  CUSTOM_DATA_GROUPS_SET(state, payload) {
    state.custom_data.groups = payload
  },

	CUSTOM_DATA_SET_FRONT_JSON(state, payload) {
		state.custom_data.front_json = payload
	},

	CUSTOM_DATA_SET_MACROS(state, payload) {
		state.custom_data.macros = payload
	},

	CUSTOM_DATA_SET_IS_GLOBAL_COLUMNS(state, boolean) {
		state.custom_data.is_global_columns = boolean
	},

};

const actions = {

    /**
     * Get custom data tables
     * @method CustomDataAxiosGetTables
     */
   async CustomDataAxiosGetTables({ commit, dispatch, state, getters }, data) {
		 const {
			 botId,
			 show_table_by_key_in_column = '',
			 full_list = false
		 } = data

      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = botId || state.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/list`
      const params = { bot_id }

      //dispatch("updateAjaxDialog", [true, true]);
      await axios.get(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
        params
      })
          .then( async (response) => {
                //dispatch("updateAjaxDialog", [false, false]);

                if ( response?.data?.status === 'success' && response.data?.data ) {
									let tables = response.data.data

									//end Filter tables by role permissions
									if(
										!getters.user?.root &&
										!full_list &&
										getters.user?.role?.permissions?.custom_data?.models &&
										getters.user?.role?.permissions?.custom_data?.models?.[bot_id]
									) {
										const currModels = getters.user.role.permissions.custom_data?.models[bot_id]
										tables = tables.filter(
											item => {
												//ignore Role and get table by key in columns_json
												if(
													show_table_by_key_in_column &&
													show_table_by_key_in_column in item?.columns_json
												) return item

												if(currModels?.[item.name]?.canShow) return item
											}
										) || []
									}
									//end Filter tables by role permissions

                  commit('CUSTOM_DATA_SET_TABLES',tables)
                  success = true;
                }
              },
              (err) => {

              dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
              }
          );

      return success;
    },

    /**
     * Create custom data model
     * @method CustomDataAxiosCreateModel
     * @param name - model name
     * @param description  - model description
     * @param columns_json - model columns
     */
     async CustomDataAxiosCreateModel({ dispatch, state }, {name, description, columns_json, statistics_json, front_json, group_id}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/custom_modules/create_update_module`
      // const mapping_object = {}

      let request = {
        bot_id,
        user_id,
        name,
        description,
        columns_json,
        statistics_json,
				front_json,
        group_id
      }
      dispatch('updateAjaxDialog', [true, true]);

      await axios.post(url, request, {
        headers: {
          "X-CSRF-TOKEN": token
        },
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                  success = true;
                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.created') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

    /**
     * Delete custom data model with all data
     * @method CustomDataAxiosDeleteModel
     * @param name - model name
     */
    async CustomDataAxiosDeleteModel({ dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;
      const user_id = state.user.id;

      const url = `${engine_url}/kw/custom_modules/delete_module/`


      let request = {
        bot_id,
        user_id,
        name
      }

      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },
          data:request
      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.deleted') ]);
                }
              },
              (err) => {
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

	async customDataAxiosCreateModuleAi({ dispatch, state }, data) {
		const { prompt, openai_token, name } = {...data}
		const token = Vue.$cookies.get('token_e')
		const engine_url = state.engine_settings.active_url;
		const bot_id = state.bot_settings.active_id;
		const url = `${engine_url}/kw/custom_modules/${name ? 'edit_module_ai' : 'create_module_ai'}`

		let request = {
			bot_id,
			prompt,
			openai_token,
		}

		//if edit mode
		if(name) request['name'] = name

		return await axios.post(url, request, {
			headers: {
				"X-CSRF-TOKEN": token
			},
		})
			.then( async (response) => {
					if ( response?.data?.status === 'success' ) {
						dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.created') ]);
						return response
					}
				},
				(err) => {
					dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
					return err
				}
			);

	},

    /**
     * Clear custom data model  data
     * @method CustomDataAxiosClearModel
     * @param name - model name
     */
    async CustomDataAxiosClearModel({ dispatch, state }, {name}) {

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = state.engine_settings.active_url;
      const bot_id = state.bot_settings.active_id;

      const url = `${engine_url}/kw/custom_modules/${name}/clear_all/?bot_id=${bot_id}`

      dispatch('updateAjaxDialog', [true, true]);

      await axios.delete(url, {
        headers: {
          "X-CSRF-TOKEN": token
        },

      })
          .then( async (response) => {
               //dispatch('updateAjaxDialog', [false, false]);
                if ( response?.data?.status === 'success' ) {
                 success = true;

                 dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.cleared') ]);
                }
              },
              (err) => { 
               dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
              }
          );

      return success;
    },

  /**
   * @name customDataAxiosGetFilters
   * @description get list for Filters
   * @param commit
   * @param dispatch
   * @param state
   * @param multiple_entity
   * @returns {Promise<void>}
   */
  async customDataAxiosGetFilters({ commit, dispatch, state }, data) {
		const {multiple_entity, botId} = data
    commit('CUSTOM_DATA_SET_FILTERS_EMPTY')
    commit('CUSTOM_DATA_SELECTED_FILTERS_EMPTY')
		commit('CUSTOM_DATA_SET_TOGGLE_ACTIVE_FILTER', null)

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = botId || state.bot_settings.active_id;
    const url = `${engine_url}/kw/custom_modules/get/${multiple_entity}`
    const params = { bot_id }

    dispatch('updateAjaxDialog', [true, true]);

    await axios({
      method: 'GET',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
      params,
    })
      .then( (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if ( response?.data?.status === 'success' ) {
            const filters = response?.data?.data?.columns_json
            const filtersValues = response?.data?.filters

            //get saved filters in state
            let savedFilters = response?.data?.users_filters

						//Concat filters from Local storage
						let localStorageFilters = localStorage.getItem('cd_local_storage_filters')
						if(localStorageFilters) {
							try {

								localStorageFilters = localStorageFilters
									? JSON.parse(localStorageFilters)
									: []

								localStorageFilters = localStorageFilters.filter(item => item?.custom_module_name === multiple_entity)
								//concat filters from Local storage
								if(localStorageFilters?.length) {
									savedFilters = savedFilters.concat(localStorageFilters)
								}

							} catch(e) {
								this.$consoleLogHandler({text: 'Error on Parse cd_local_storage_filters ', data: e})
							}
						}
						//end Concat filters from Local storage

            //get columns from columns_chooser
            let columns_chooser = response?.data?.columns_chooser
						commit('CUSTOM_DATA_SET_IS_GLOBAL_COLUMNS', true)

						//check personal columns in filters
						if( savedFilters?.length ){
							let my_columns_chooser = savedFilters.find(item => {
								return !item?.name && Number(item?.user_id) === state.user.id && item?.columns_chooser?.length
							})?.columns_chooser

							if(my_columns_chooser?.length) {
								//set my_columns_chooser in columns_chooser
								columns_chooser = my_columns_chooser
								commit('CUSTOM_DATA_SET_IS_GLOBAL_COLUMNS', false)
								//filters without my_columns_chooser
								savedFilters = savedFilters.filter(item => !!item?.name)
							}
						}

						//set saved filters in state
						commit('CUSTOM_DATA_SET_SAVED_FILTERS', savedFilters)

						//set columns from columns_chooser
            if (columns_chooser?.length) {
              dispatch('_customDataSetSavedColumnsData', columns_chooser)
            } else {
              dispatch('_customDataSetSavedColumnsData', [])
            }

            let arr = []

            for (const f in filters) {
              let obj = filters[f]
              obj['name'] = f
              obj['is_association'] = !!filters[f]?.association
              obj['values'] = filtersValues?.[f]
              obj['conditions'] = state.custom_data.filters_schema_json[filters[f].type]?.conditions

              dispatch('customDataSetSelectedFilter', {
                key: f,
                values: {
                  value: null,
                  condition: null,
                  type: filters[f].type,
                  is_association: !!filters[f]?.association,
                  label: filters[f]?.label || null,
                }
              })

							//hide_in_filter from model keys
							if(!obj?.hide_in_filter) {
								arr.push(obj)
							}

              //for Linked Tables by model_name
              if (
								filters[f].association
								&& !('association_fetch' in filters[f])
							) {
                const modelName = filters[f].association.model_name
                const modelField = filters[f].association?.model_field
                const modelFieldLabel = filters[f].association?.model_field_label
								const fetch_limit = filters[f]?.association?.fetch_limit
								const get_full_data = filters[f]?.association?.get_full_data
                dispatch('_customDataAxiosGetLinkedTablesValues', {
                  modelName: modelName,
                  modelField: modelField,
                  linkedColumn: f,
									bot_id: bot_id,
									fetch_filter: filters[f]?.fetch_filter,
									modelFieldLabel: modelFieldLabel,
									fetch_limit: fetch_limit,
									get_full_data: get_full_data,
                })
              }
            }

            commit('CUSTOM_DATA_SET_FILTERS', arr)

						//set front_json
						commit('CUSTOM_DATA_SET_FRONT_JSON', response?.data?.data?.front_json || {})
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );
  },

  /**
   * @name customDataAxiosSaveFilters
   * @description save selected filters
   * @param state
   * @param commit
   * @param dispatch
   * @param nameModule
   * @param nameFilter
   * @param filters_json
   * @param with_columns
   * @returns {Promise<void>}
   */
  async customDataAxiosSaveFilters({ state, commit, dispatch }, { nameModule, nameFilter, filters_json, with_columns }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;
    const custom_module_id = state.custom_data.tables.find(item => item.name === nameModule).id
    const url = `${engine_url}/kw/custom_modules/create_update_filter`

    let request = {
      custom_module_id,
      user_id,
      name: nameFilter,
      filters_json,
    }

    if (with_columns) {
      request['columns_chooser'] = state.custom_data.columns_data
    }

    dispatch('updateAjaxDialog', [true, true]);
    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          //dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            commit('CUSTOM_DATA_PUSH_SAVED_FILTERS', response.data.data)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_created') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },

  /**
   * @name customDataAxiosDeleteFilter
   * @description delete filter by name filter and id module
   * @param state
   * @param commit
   * @param dispatch
   * @param nameModule
   * @param nameFilter
   * @returns {Promise<void>}
   */
  async customDataAxiosDeleteFilter({ state, commit, dispatch }, { nameModule, nameFilter }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;
    const custom_module_id = state.custom_data.tables.find(item => item.name === nameModule).id

    const url = `${engine_url}/kw/custom_modules/delete_filter`

    let request = {
      custom_module_id,
      name: nameFilter,
      user_id,
    }
    dispatch('updateAjaxDialog', [true, true]);
    await axios.delete(url, {
      headers: {
        "X-CSRF-TOKEN": token
      },
      data:request,
    })
      .then( async (response) => {
          if ( response?.data?.status === 'success' ) {
            commit('CUSTOM_DATA_DELETE_SAVED_FILTERS', nameFilter)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_deleted') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },

	customDataDeleteLocalStorageFilter({commit}, {name}) {
		let localStorageFilters = localStorage.getItem('cd_local_storage_filters')

		try {
			localStorageFilters = localStorageFilters
				? JSON.parse(localStorageFilters)
				: []

			localStorageFilters = localStorageFilters.filter((el) => el.name !== name)

			localStorage.setItem(
				'cd_local_storage_filters',
				JSON.stringify(localStorageFilters),
			)

			commit('CUSTOM_DATA_DELETE_SAVED_FILTERS', name)

		} catch (e) {
			this.$consoleLogHandler({text: 'Error on Parse cd_local_storage_filters ', data: e})
		}
	},

  /**
   * @name customDataActivateSavedFilter
   * @description set active filters by click on saved filters
   * @param state
   * @param commit
   * @param dispatch
   * @param data
   */
  customDataActivateSavedFilter({ state, commit, dispatch } , data) {
    //clear data values and condition in Filters List (set null)
    for (let f in state.custom_data.selected_filters) {
      commit('CUSTOM_DATA_SET_SELECTED_FILTER_VALUE', {key: f, value: null})
      commit('CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION', {key: f, condition: null})
    }

    if (data?.filters_json) {
      for(let item of data.filters_json) {
        let name = item[0]
        let value = item[1].value
        let condition = item[1].condition

        commit('CUSTOM_DATA_SET_SELECTED_FILTER_VALUE', {key: name, value: value})
        commit('CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION', {key: name, condition: condition})
      }
    }

    //set columns from saved filter or set saved from model
    if (data?.columns_chooser) {
      // commit('CUSTOM_DATA_SET_COLUMNS_DATA', data.columns_chooser)
			//actualization columns from default_columns_data
			dispatch('_customDataColumnsActualization', data.columns_chooser)
      commit('CUSTOM_DATA_SET_COLUMNS_DATA_ACTIVATED', data.columns_chooser)
    } else {
      let columns;
      (state.custom_data.saved_columns_data?.length)
        ? columns = state.custom_data.saved_columns_data
        : columns = state.custom_data.default_columns_data

      // commit('CUSTOM_DATA_SET_COLUMNS_DATA', _.cloneDeep(columns) )
			//actualization columns from default_columns_data
			dispatch('_customDataColumnsActualization', _.cloneDeep(columns))
      commit('CUSTOM_DATA_SET_COLUMNS_DATA_ACTIVATED', _.cloneDeep(columns))
    }
  },

  /**
   * @name _customDataAxiosGetLinkedTablesValues
   * @description get Linked table for customDataAxiosGetFilters ACTION
   * @param commit
   * @param dispatch
   * @param state
   * @param modelName
   * @param modelField
   * @param linkedColumn
   * @param bot_id
   * @param fetch_filter
   * @param modelFieldLabel
   * @param fetch_limit
   * @param get_full_data
   * @returns {Promise<void>}
   * @private
   */
  async _customDataAxiosGetLinkedTablesValues({ commit, dispatch, state }, {
		modelName,
		modelField,
		linkedColumn,
		bot_id,
		fetch_filter,
		modelFieldLabel,
		fetch_limit,
		get_full_data = false,
	}) {
    if(!modelName && !linkedColumn) return

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const url = `${engine_url}/kw/custom_modules/${modelName}/list?`

    const params = {
      bot_id: bot_id,
    }
		const data = {
			offset: 0,
			limit: fetch_limit ? Number(fetch_limit) : 10000,
		}

		//set filter for list
		if(fetch_filter) {
			data['filter'] = fetch_filter
		}

    dispatch('updateAjaxDialog', [true, true]);

    await axios({
      method: 'POST',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
			params: params,
			data: data,
    })
      .then( (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if ( response?.data?.status === 'success' ) {
            const rows = response?.data?.data?.rows

            let arr = []
            if(rows.length) {
              let name = '';

              if (modelField) {
                name = modelField
              } else {
                const objFirstItem = rows[0]
                name = Object.keys(objFirstItem).find(function(item) {
                  if ( RegExp('name').test(item) ) {
                    return item
                  }
                })
              }

							let label = null
							const objFirstItem = rows[0]
							label = Object.keys(objFirstItem).find(function(item) {
								if ( RegExp(modelName + '_label').test(item) ) {
									return item
								}
							})

							//Set label from modelFieldLabel
							if(!label && modelFieldLabel) label = modelFieldLabel
							//end Set label from modelFieldLabel

              for (let i in rows) {
								//fix in object for 121 data
                let obj = {
                  name: rows[i][name],
                  id: rows[i][modelField],
									is_id: rows[i].id //TODO: fix for 121 user_id on save
                }

								//add label
								if(label) obj['label'] = rows[i][label]

								//Add Color if exist in model name '__color'
								Object.keys(rows[i]).find(function(item) {
									if ( RegExp('__color').test(item) ) obj['color'] = rows[i][item]
								})
								//end Add Color if exist

                if (rows[i][name]) {
                  arr.push(obj)
                }
              }
            }

            commit('CUSTOM_DATA_SET_LINKED_TABLE_VALUES', {
              column: linkedColumn,
              values: arr
            })

						//set Full Data for Linked Table
						if(get_full_data) {
							commit('CUSTOM_DATA_SET_LINKED_TABLE_FULL_DATA', {
								column: linkedColumn,
								values: rows
							})
						}

          }
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Error', 'Linked Table ERROR' ]);
        }
      );
  },

	async _customDataFetchGetLinkedTablesValues({ commit, dispatch, state }, {
		modelName,
		modelField,
		linkedColumn,
		bot_id,
		fetch_filter,
		modelFieldLabel,
		fetch_limit,
		get_full_data = false,
		params = {}
	}) {
		if(!modelName && !linkedColumn) return

		const token = Vue.$cookies.get('token_e')
		const engine_url = state.engine_settings.active_url;
		const url = `${engine_url}/kw/custom_modules/${modelName}/list?`

		params = {
			bot_id: bot_id,
			...params,
		}
		const data = {
			offset: 0,
			limit: fetch_limit ? Number(fetch_limit) : 10000,
		}

		//set filter for list
		if(fetch_filter) {
			data['filter'] = fetch_filter
		}

		dispatch('updateAjaxDialog', [true, true]);

		return await axios({
			method: 'POST',
			url: url,
			headers: {
				'X-CSRF-TOKEN': token,
			},
			params: params,
			data: data,
		})
			.then( (response) => {
					dispatch('updateAjaxDialog', [false, false]);

					if ( response?.data?.status === 'success' ) {
						const rows = response?.data?.data?.rows

						let arr = []
						if(rows.length) {
							let name = '';

							if (modelField) {
								name = modelField
							} else {
								const objFirstItem = rows[0]
								name = Object.keys(objFirstItem).find(function(item) {
									if ( RegExp('name').test(item) ) {
										return item
									}
								})
							}

							let label = null
							const objFirstItem = rows[0]
							label = Object.keys(objFirstItem).find(function(item) {
								if ( RegExp(modelName + '_label').test(item) ) {
									return item
								}
							})

							//Set label from modelFieldLabel
							if(!label && modelFieldLabel) label = modelFieldLabel
							//end Set label from modelFieldLabel

							for (let i in rows) {
								//fix in object for 121 data
								let obj = {
									name: rows[i][name],
									id: rows[i][modelField],
									is_id: Number(rows[i].id) //TODO: fix for 121 user_id on save
								}

								//add label
								if(label) obj['label'] = rows[i][label]

								//Add Color if exist in model name '__color'
								Object.keys(rows[i]).find(function(item) {
									if ( RegExp('__color').test(item) ) obj['color'] = rows[i][item]
								})
								//end Add Color if exist

								if (rows[i][name]) {
									arr.push(obj)
								}
							}
						}


						return arr

						// commit('CUSTOM_DATA_SET_LINKED_TABLE_VALUES', {
						// 	column: linkedColumn,
						// 	values: arr
						// })
						//
						// //set Full Data for Linked Table
						// if(get_full_data) {
						// 	commit('CUSTOM_DATA_SET_LINKED_TABLE_FULL_DATA', {
						// 		column: linkedColumn,
						// 		values: rows
						// 	})
						// }

					}
				},
				(err) => {
					this.$store.dispatch('updateAjaxDialogError', [ err, 'Error', 'Linked Table ERROR' ]);
					return []
				}
			);
	},

  customDataClearLinkedTableValues({ commit }) {
    commit('CUSTOM_DATA_CLEAR_LINKED_TABLE_VALUES');
  },

  customDataSetEmptySelectedFilter({ commit }, {key, values}) {
    commit('CUSTOM_DATA_SET_EMPTY_SELECTED_FILTER', {key, values})
  },

  customDataSetSelectedFilter({ commit }, {key, values}) {
    commit('CUSTOM_DATA_SET_SELECTED_FILTER', {key, values})
  },

  customDataSetSelectedFilterValue({ commit }, {key, value}) {
    commit('CUSTOM_DATA_SET_SELECTED_FILTER_VALUE', {key, value})
  },
  customDataSetSelectedFilterCondition({ commit }, {key, condition}) {
    commit('CUSTOM_DATA_SET_SELECTED_FILTER_CONDITION', {key, condition})
  },

  customDataDeleteSelectedFilter({ commit }, key) {
    commit('CUSTOM_DATA_DELETE_SELECTED_FILTER', key)
  },
  customDataDeleteSelectedFilterInArray({ commit }, {key, value}) {
    commit('CUSTOM_DATA_DELETE_SELECTED_FILTER_IN_ARRAY', {key, value})
  },
  customDataSetSelectedFiltersEmpty({ commit }) {
    commit('CUSTOM_DATA_SELECTED_FILTERS_CLEAR_VALUES')
  },

  customDataSetColumnsData({ dispatch, getters, commit }, data) {
    if (data.length && data[0].hasOwnProperty('position')) {
			//actualization columns from default_columns_data
			dispatch('_customDataColumnsActualization', data)
    } else if(data.length) {
			let arr = []

			let position = 0
      for (let i in data) {
        if(data[i].hidden_all) continue;
        let item = {
          ...data[i],
          active: !data[i].hidden_in_table,
          position: position,
          sort: null,
        }

        arr.push(item)
				position++
      }

			// dispatch('_customDataColumnsActualization', arr)
			commit('CUSTOM_DATA_SET_COLUMNS_DATA', arr)
    }
  },
  /**
   * @name customDataSetDefaultColumnsData
   * @description save Default Columns in state
   * @param commit
   * @param data
   */
  customDataSetDefaultColumnsData({ commit }, data) {
    let arr = []
		let position = 0
    for (let i in data) {
      if(data[i].hidden_all) continue;
      let item = {
        ...data[i],
        active: !data[i].hidden_in_table,
        position: position,
        sort: null,
      }

      arr.push(item)
			position++
    }
    commit('CUSTOM_DATA_SET_DEFAULT_COLUMNS_DATA', arr)
  },

  customDataSetColumnsDataFromActivated({ dispatch, getters, commit }, mode) {
      if (mode) {
        commit('CUSTOM_DATA_SET_COLUMNS_DATA', [])
        commit('CUSTOM_DATA_SET_COLUMNS_DATA_ACTIVATED', [])
      } else {
				//actualization columns from default_columns_data
				dispatch('_customDataColumnsActualization', getters.custom_data_columns_data_activated)
      }
  },

	async _customDataColumnsActualization({ getters, commit }, data) {
		let enumMap = new Map(data?.map(item => [item.name, item]));

		let arr = getters.custom_data_default_columns_data.reduce((acc, curr) => {
			let enumItem = enumMap.get(curr.name);
			const retainedValues = {
				active: enumItem?.active || false,
				position: enumItem?.position || 0,
				sort: enumItem?.sort || null,
			};

			let updatedObject = {};

			Object.keys(curr).forEach(key => {
				if (key in retainedValues) {
					updatedObject[key] = retainedValues[key];
				} else {
					updatedObject[key] = curr[key];
				}
			});

			acc.push(updatedObject)
			return acc
		}, [])

		commit('CUSTOM_DATA_SET_COLUMNS_DATA', arr)
	},

  customDataSetToggleActiveFilter({ dispatch, commit }, value) {
    commit('CUSTOM_DATA_SET_TOGGLE_ACTIVE_FILTER', value)
    dispatch('customDataActivateSavedFilter', value)
  },

  //Columns ACTIONS
  customDataToggleColumnActive({ state, commit }, {name, value}) {
		function findWithAttr(array, attr, value) {
			for(let i = 0; i < array.length; i += 1) {
				if(array[i][attr] === value) {
					return i;
				}
			}
			return -1;
		}

		const index = findWithAttr(
			state.custom_data.columns_data,
			'name',
			name
		)

		if(index >= 0) {
			commit('CUSTOM_DATA_TOGGLE_COLUMN_ACTIVE', {index, value})
		}

  },
  customDataChangeOrderColumn({ state, commit }, data) {
    commit('CUSTOM_DATA_CHANGE_ORDER_COLUMN', data)
  },

  /**
   * @name customDataAxiosSaveColumnsData
   * @description Save columns for Model
   * @param state
   * @param dispatch
   * @param commit
   * @param name
   * @param reset
   * @returns {Promise<void>}
   */
  async customDataAxiosSaveColumnsData({ state, dispatch, commit, }, {name, reset}) {
		if(!name) return

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = state.bot_settings.active_id;
    const columns_chooser =
      reset ? [] : state.custom_data.columns_data_activated

    const url = `${engine_url}/kw/custom_modules/update_columns_chooser`

    let request = {
      bot_id,
      name,
      columns_chooser,
    }
    dispatch('updateAjaxDialog', [true, true]);

    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.columns_chooser.columns_saved_success') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  async _customDataSetSavedColumnsData({commit}, payload) {
    await commit('CUSTOM_DATA_SET_SAVED_COLUMNS_DATA', payload)
  },
  customDataResetColumnsDataToDefault({ state, commit, dispatch }, name) {
		//ignoring axios if name === null
    dispatch('customDataAxiosSaveColumnsData', {name: name, reset: true})
    Vue.set(state.custom_data, 'columns_data', state.custom_data.default_columns_data)
  },

  customDataResetColumnsDataForFilterToSaved({ state, dispatch }) {
    let columns;

    (state.custom_data.saved_columns_data?.length)
      ? columns = _.cloneDeep(state.custom_data.saved_columns_data)
      : columns = _.cloneDeep(state.custom_data.default_columns_data)

    // commit('CUSTOM_DATA_SET_COLUMNS_DATA', columns)
		//actualization columns from default_columns_data
		dispatch('_customDataColumnsActualization', columns)
    // Vue.set(state.custom_data, 'columns_data', state.custom_data.saved_columns_data)
  },

  
  async customDataAxiosGetGroups({ state, dispatch, commit }) {
    const url = state.engine_settings.active_url + '/kw/custom_modules/group_list';
    const token = Vue.$cookies.get('token_e');
    const bodId = state.bot_settings.active_id

    try {
      const response = await axios.post(url, { bot_id: bodId },  {
        headers: {
          'X-Csrf-Token': token,
        },
      });
      commit('CUSTOM_DATA_GROUPS_SET', response.data.data)
    } catch (error) {
      console.error('Error:', error);
    }
  },
  //end Columns ACTIONS

	// customDataAxiosGetMacros

	customDataSetIsGlobalColumns({ commit }, boolean) {
		commit('CUSTOM_DATA_SET_IS_GLOBAL_COLUMNS', boolean)
	},
};

const getters = {
    custom_data_tables: (state)=>{
      return state.custom_data.tables
    },

   custom_data_tables_select: (state)=>{
    let tables = []
       for( let t in state.custom_data.tables){
           tables.push({id: state.custom_data.tables[t].id, name:state.custom_data.tables[t].name})
       }
  return tables
  },

  custom_data_filters: (state) => state.custom_data.filters,
  custom_data_linked_tables_values: (state) => state.custom_data.linkedTablesValues,
  custom_data_linked_tables_values_creation_modal: (state) => state.custom_data.linkedTablesValuesCreationModal,
  custom_data_linked_tables_full_data: (state) => state.custom_data.linkedTablesFullData,
  custom_data_selected_filters: (state) => state.custom_data.selected_filters,
  custom_data_saved_filters: (state) => state.custom_data.saved_filters,

  custom_data_toggle_active_filter: (state) => state.custom_data.toggle_active_filter,

  custom_data_columns_data: (state) => state.custom_data.columns_data,
  custom_data_columns_data_activated: (state) => state.custom_data.columns_data_activated,
  custom_data_saved_columns_data: (state) => state.custom_data.saved_columns_data,
  custom_data_default_columns_data: (state) => state.custom_data.default_columns_data,

  get_custom_data_groups: (state) => state.custom_data.groups,

  get_custom_data_front_json: (state) => state.custom_data.front_json,
  get_custom_data_macros: (state) => state.custom_data.macros,

	get_custom_data_is_global_columns: (state) => state.custom_data.is_global_columns,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
