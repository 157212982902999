<template>
  <div class="cd_create">
    <v-btn
      v-if="userCanSave()"
      v-show="!aiMagicDrawer"
      class="cd_create__btn-ai-magic"
      fixed
      top
      right
      color="primaryLighten"
      dark
      @click.stop="aiMagicDrawer = !aiMagicDrawer"
      elevation="0"
    >
      <v-icon left>mdi-magic-staff</v-icon>
      AI magic
      <div class='b_l_quad'>
        <div class='button_spots' :key="i" v-for="(item, i) in 51"></div>
      </div>
    </v-btn>

    <div class="cd_create_wrapper">
      <v-container class="cd_create_wrapper--top">
        <!--SELECT MODEL-->
        <v-autocomplete
          v-model="selectedTable"
          :items="[{id:-1,name:$t('modules.customdata.model.new')}].concat(custom_data_tables_grouped)"
          item-text="name"
          item-value="id"
          :placeholder="$t('modules.customdata.select_model')"
          :hint="$t('modules.customdata.select_model')"
          persistent-hint
          outlined
          hide-details
          dense
          background-color="white"
          class="elevation-0"
        />
      </v-container>

      <div class="cd_create_wrapper--body">

        <v-form ref="form" v-model="valid">
          <v-container class="py-0">
            <v-layout>
              <v-switch
                class="mt-1 mr-3"
                v-model="activateRegUserId"
                @change="changeRegUserId"
                color="primary"
                hide-details
              >
                <template v-slot:label>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                      >mdi-card-account-details-outline</v-icon>
                    </template>
                    {{$t('modules.customdata.model.add_reg_user_id_label')}}
                  </v-tooltip>
                </template>
              </v-switch>

              <v-text-field
                :label="$t('modules.customdata.model.name')"
                v-model="name"
                :rules="nameRules"
                :disabled="selectedTable && selectedTable!==-1"
                :hint="
                  $t('modules.customdata.model.name') + ' ' +
                  $t('modules.customdata.model.not_editable_after_creation')
                "
                outlined
                dense
                background-color="white"
                class="mr-3"
              />
              <v-text-field
                :label="$t('modules.customdata.model.description')"
                v-model="description"
                :rules="descRules"
                outlined
                dense
                background-color="white"
                class="mr-3"
              ></v-text-field>

              <v-select
                v-model="groupForTable"
                :items="[{id:-1,name:$t('modules.customdata.groups.withoutGroup')}].concat(get_custom_data_groups)"
                item-text="name"
                item-value="id"
                :placeholder="$t('modules.customdata.groups.chooseGroup')"
                :hint="$t('modules.customdata.groups.chooseGroup')"
                persistent-hint
                outlined
                dense
                background-color="white"
              >
              </v-select>

            </v-layout>
          </v-container>

          <v-divider />

          <v-sheet
            height="300"
            color="gray5"
            class="py-1"
          >
          <vue-scroll>
            <v-container class="py-0">

              <p>{{$t('modules.customdata.model.json')}}</p>

              <v-row>
                <v-col md="6">
                  <div ref="builder">
                    <template
                      v-for="(item, key) in json_obj"
                    >
                      <v-card
                        :key="key"
                        elevation="3"
                        outlined
                        class="mb-2 position-relative"
                      >
                        <v-btn
                          class="mt-2"
                          color="error"
                          absolute
                          icon
                          plain
                          right
                          @click="deleteField(key)"
                        ><v-icon>mdi-delete-forever</v-icon></v-btn>
                        <v-card-title>
                          {{key}}
                        </v-card-title>

                        <v-card-text>
                          <v-select
                            v-model="item.type"
                            :items="typeItems"
                            :label="$t('modules.customdata.model.type')"
                            dense
                            outlined
                            hide-details
                            class="mb-5"
                          />
                          <v-text-field
                            v-model="item.label"
                            :label="$t('modules.customdata.model.label')"
                            dense
                            outlined
                            hide-details
                          />
                        </v-card-text>
                      </v-card>
                    </template>

                    <v-form v-model="newFieldValid">
                      <v-layout class="mt-6">
                        <v-btn
                          class="mr-2 text-none"
                          @click="addField()"
                          color="primary"
                          :disabled="!newFieldValid"
                          max-height="42"
                          min-height="42"
                          elevation="0"
                        >
                          {{$t('modules.customdata.model.add_field')}}
                        </v-btn>

                        <v-text-field
                          color="primary"
                          background-color="white"
                          :label="$t('modules.customdata.model.uniq_field')"
                          v-model="new_field"
                          :rules="nameRules"
                          required
                          outlined
                          dense
                        />
                      </v-layout>
                    </v-form>
                  </div>
                </v-col>
                <v-col md="6">
                  <VJsoneditor
                    v-model="json_obj_in_editor"
                    :options="options"
                    :height="json_obj_editor_height"
                  />
                </v-col>
                <v-col md="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>{{$t('modules.customdata.model.json_example')}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <pre class="json-example">
{
  "minimal":{"type":"INTEGER"},
  "column_name": { //can be "cd_map", "cd_chart", "cd_image_slider", "cd_image_list", "cd_chat"
    "type":"STRING", //Required, can be also "INTEGER" | "DATE" | "BOOLEAN" | "FLOAT" | "TEXT"
    "display_in_table":"image", //optional: image
    "align":"start", //optional align of header in table
    "default":"default value", //optional
    "unique":"true"|"false", //optional
    "label":"custom name", //optional
    "association_labels": { //optional get Labels from associated model in table
      "model_name1": "association_key", //key: model_name, value: key with association object
      "model_name2": "association_key_another",
    },
    "association_label": "association_key" //optional get Label from associated model without object for multiple models
    "width": "10", //column width
    "hidden_in_table":"true"|"false", // optional; if true, will be turned off in columns chooser
    "hidden_all":"true"|"false", // optional; if true, will be hidden in all contexts (except in filters)
    "hide_in_filter":"true"|"false", // optional; if true, will be hidden in filters
    "sortable":"true"|"false", // optional; if true, column sortable in table
    "non_editable":"true"|"false", // optional; if true, column not editable in table
    "display_type":"link", // optional; if 'link', column has clickable link opening in new tab
    "click_action":"open", // optional; if 'open', column has button opening edit modal
    "file_upload": true, //optional, for file uploading; important only "type": "JSON",
    "subtype": 'show_image_in_slider' //optional collect all field and generate image slider
    "total_sum": true, //optional, for total sum in table (should be enabled in json_front.table_stylization.total_row )
    "association": {
      "attributes": ["column_name", "outlet_image"] //keys from where take values for subtype, except main key
      "subtype": "show_image_in_slider" // 'show_image_in_slider' for static slider
      "model_field_label": "image_name" // if need show in select text from label
      "as": "field_name_from_associated" // show in table column if accociation with key "as"
      "fetch_limit": 10000 //number of limit for GetLinkedTablesValues - 10000 default
      "get_full_data": true, //get all data from row in associated model
    },
    hidden_in_create_popup: true //hide in modal on Create new one,
    fetch_on_create: ['column_name_from_associated_model', 'field_name']
                    //list of fields which need on creating from associated model and show in table for info
    "fetch_filter": {"id": [1205, 1326]} //filter for Associations fields
    "auto_select_first": true //auto select first elem from Associated list
    "auto_select_associated_on_change": ["associated_field_name"]
                    //autoselect another field on create, must be hidden_in_create_popup
  },
  "sting_name": {
    "type":"STRING",
    "string_input": { //optional for STING type, need for create/edit in modal
      "type": "date", //time
      "format": "DD.MM.YYYY" //HH:mm
    },
    "rules": [ //field Rules
      "required",
      "email",
      "digits",
      "minLimit",
      "custom_minLimit_10", //10 - can be different
      "custom_maxLimit_10", //10 - can be different
      "latin",
      "latinDigits",
      "stringMaxLength", //255
      "json"
    ],
    "validate": { //custom validation rules
      "required_field_empty": "start", //disabled if empty field
      "required_field_not_empty": "photo_finish", //disabled if NOT empty field
      "disabled_in_table_on_edit": true //disable in table if has value
      "rules": {
        "type": "time", // ("date")
        "val_from_field": "start", //(optional) if not exist -> date Now()
        "action": "gt" // ("lt") should be greater than than 'validate_field'
        "ignore_in_table": true //ignore date limit in table
      },
      "disable_clearable": { //disable clearable if field "photo_finish" not empty for type date/time
        "required_field": "photo_finish", //field which not empty
      }
    },
    "edit_type": "file_upload" //add functional for Upload single File to FileManager for type STRING only
  },
    "sting_highlight": {
      "type":"STRING", //'STRING', 'TEXT', 'FLOAT', 'INTEGER', 'BIGINT'
      "highlight_rules": {
        "type": "time", //calculate by string
        "rules": [ //array of rules with different conditions
          {
            "0": "start", //field name for calculate
            "1": "factual_start", //field name for calculate
            "action": "lt", //"gt", "lt", "eq", "gte", "lte"
            "color": "red" //hex code color for highlighting
          },
          {
            "0": "start",
            "1": "factual_start",
            "action": "gt",
            "color": "green"
          }
        ]
      }
    }
  }
}
                        </pre>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <v-divider class="my-5" />
              <p>{{$t('modules.customdata.model.json_statistics')}}</p>
              <VJsoneditor
                v-model="statistics_json_in_editor"
                :options="options"
                :height="checkHeightJEditor(statistics_json_in_editor)"
              />
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Example {{$t('modules.customdata.model.json_statistics')}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pre class="json-example">
[
  {
    "type": "bar",
    "color": "#D6E6F2",
    "label": "Table label",
    "measure": "sum", //count || sum
    "group_by": "field_name", //columns
    "sum_field": "field_name" //rows
  }
]
                    </pre>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-divider class="my-5" />
              <p>{{$t('modules.customdata.model.json_front')}}</p>
              <VJsoneditor
                v-model="front_json_in_editor"
                :options="options"
                :height="checkHeightJEditor(front_json_in_editor)"
              />
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>{{$t('modules.customdata.model.json_example')}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pre class="json-example">
{
  "modal_entity": {
    "activity_name": {
      "type": "ASSOCIATION", //for Association
      "label": "Активність",
      "label_from_column_name": "gps", //get label name from column json
      "editable": true || false // when editable show SELECT
      "icon": "mdi-phone", //mdi icons
      "icon_color": "red", //can be HEX code
    },
    "minimal": { //key for value
      "type": "STRING", //STRING, TEXT, INTEGER, BIGINT, BOOLEAN
      "label": "Custom Name",
      "label_from_column_name": "gps", //get label name from column json
      "icon": "mdi-phone", //mdi icons
      "icon_color": "red", //can be HEX code
      "editable": true || false
    },
    "cd_map": {
      "type": "MAP",
      "label": "Coordinates",
      "editable": true || false,
      "latitude_key": "latitude", //key from row for value
      "longitude_key": "longitude", //key from row for value
      "map_zoom": 10, //default zoom 10
    },
    "cd_map_markers": {
      "type": "MAP_MARKERS",
      "label": "Coordinates",
      "data": "column_name", //data from column name [ {"lat":"50.4507433","long":"30.4429373","action":"Фото старта (Invalid Date)"} ] action - marker label text
      "map_zoom": 10, //default zoom 10
    },
    "cd_image_slider": {
      "type": "SLIDER",
      "label": "Images",
      "img_fields": [
        "outlet_image" //key from row for value
      ]
    },
    "custom_value_chart": {
      "type": "CHART",
      "label": "Coordinates",
      "editable": true || false
    },
    "expansion-panels": [ //expansion Panel
      {
        "label": "Адреса",
        "header_color": "#d9edf7",
        "icon": "mdi-map-marker-outline",
        "icon_color": "black",
        "items": {
          "petra_id": {
            "label": "Custom Label",
            "label_from_column_name": "gps", //get label name from column json
            "type": "BIGINT",
            "editable": true, //if  true show toggle
            "edit_toggle": false, //need for toggle
            "icon": "mdi-account-hard-hat",
            "icon_color": "primary"
          },
          "region": {
            "type": "STRING"
          }
        }
      }
    ],
    "create_association_row": { //need for add new row in assoc table
      "type": "CREATE_ASSOCIATION_ROW",
      "label": "Призначити штраф", //label for button
      "from": "consultant_penalty", //association model_name
      "to": "penalty_id", //field in model which need update in model
      "show_if_result": "penalty_hours", //hide button if result exist (penalty_id)
      "label_if_result": "Штраф призначено", //label for alert if result exist (penalty_id)
      "icon": "mdi-currency-usd-off", //icon before button
      "icon_color": "error", //icon and button color
      "save_by_permission": "error", //check permission canAdd in model by 'from'
      "additional_fields_result": [ //show additional inforation from fileds in assoc table (get_full_data - required)
        {
          "label": "Ким назначено",
          "value": "user_name"
        },
        {
          "label": "На кого назначено",
          "value": "consultant_user_name"
        }
      ]
    }
  },

  "table_stylization": {
    "total_row": true, //show total row in table
    "colorize": { //background Color element in table
      "what": "cell", // "row" || "cell"
      "field": "ap_work_status_label", // required if need bgColor CELL
      "param_name": "bgColor", //HEX code color value in field row
      "opacity": 0.3 //opacity Number with Float 1 - no opacity
    }
  },
  "macros": {
    "bot_id": 1,
    "single": true, //button in row of table
    "multiple": false, //button in Global for multiple
  }
  "additional_crud_filter": {
    "enabled": true,
    "label": "Additional Filter",
    "default_value": true,
    "condition": true // should be equal value for add key 'additional_crud_filter' to axios
  },
  "additional_filters_config": {
    "default_condition_value": "like" //set default condition in select. "like" is "between" too
  }
}
                    </pre>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-container>
          </vue-scroll>
          </v-sheet>
        </v-form>
      </div>

      <v-divider />

      <div class="cd_create_wrapper--footer">
        <v-layout
          v-if="userCanSave()"
          :justify-center="true" class="py-4"
        >
          <v-btn
            :disabled="!(json_obj && valid)"
            color="primary"
            elevation="23"
            @click="createModel"
            small
          >{{ $t('modules.customdata.create') }}</v-btn>

          <v-btn
            v-if="selectedTable && selectedTable!==-1"
            elevation="23"
            color="error"
            class="ml-5"
            small
            @click="deleteModel"
          >
            <v-icon left>mdi-trash-can</v-icon>
            {{ $t('modules.customdata.delete') }}

          </v-btn>
        </v-layout>
      </div>
    </div>

    <v-navigation-drawer
      v-model="aiMagicDrawer"
      absolute
      right
      temporary
      light
      width="36%"
    >
      {{/** MODAL CLOSE BTN **/}}
      <v-btn
        class="modal__close-btn"
        icon
        small
        absolute
        top
        left
        @click="aiMagicDrawer = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      {{/** END MODAL CLOSE BTN **/}}

      <v-sheet class="cd_create__drawer pa-6 pt-10">
        <CreateAiMagicDataForm
          integrated_mode="drawer"
        />
      </v-sheet>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VJsoneditor from 'v-jsoneditor'
import i18n from '@/lang/i18n';
import CreateAiMagicDataForm from "@/modules/CustomData/components/CreateAiMagicDataForm.vue";

import customDataMixin from './customDataMixin';

export default {
  name: "CreateTab",

  mixins: [customDataMixin],

   components: {
    CreateAiMagicDataForm,
    VJsoneditor
  },

  data: () => ({

    options:{ mode: 'code' },
    name:"",
    description:"",
    valid: false,
    selectedTable:null,
    tables:[],
    table:{},
    json_obj: {},
    json_obj_editor_height: '213px',
    statistics_json: {},
    front_json: {},
     nameRules: [
        v => !!v || i18n.t('modules.customdata.model.name_required'),
        v => /^[A-Za-z0-9_]*$/.test(v) || i18n.t('modules.customdata.model.name_required')
      ],

     descRules: [
        v => !!v || i18n.t('modules.customdata.model.description_required'),
        v => v.length < 450 || i18n.t('modules.customdata.model.description_required')
      ],

    activateRegUserId: false,
    groupForTable: null,

    aiMagicDrawer: false,

    typeItems: [
      {
        value: 'STRING', text: i18n.t('modules.customdata.model.types.string'),
      },
      {
        value: 'TEXT', text: i18n.t('modules.customdata.model.types.text'),
      },
      {
        value: 'INTEGER', text: i18n.t('modules.customdata.model.types.integer'),
      },
      {
        value: 'BIGINT', text: i18n.t('modules.customdata.model.types.bigint'),
      },
      {
        value: 'FLOAT', text: i18n.t('modules.customdata.model.types.float'),
      },
      {
        value: 'DATE', text: i18n.t('modules.customdata.model.types.date'),
      },
      {
        value: 'BOOLEAN', text: i18n.t('modules.customdata.model.types.boolean'),
      },
    ],

    new_field: null,
    newFieldValid: false,
  }),

  async mounted() {
    await this.CustomDataAxiosGetTables({
      botId: this.bot_settings?.active_id,
    });
    this.customDataAxiosGetGroups();
    this.tables = this.custom_data_tables
  },

  watch: {
    selectedTable(t) {
      let tt_obj=null
      for(let tt of this.tables){
        if(tt.id === t){
          tt_obj=tt
          break;
        }
      }

      if(tt_obj!==null){
        this.name = tt_obj.name
        this.description = tt_obj.description
        this.json_obj = tt_obj.columns_json
        this.statistics_json = tt_obj?.statistics_json
        this.front_json = tt_obj?.front_json
        this.activateRegUserId = !!this.json_obj?.reg_user_id;
        this.groupForTable = tt_obj.group_id
      }else{
          this.name = ""
        this.description =""
        this.json_obj = {}
        this.statistics_json = {}
        this.front_json = {}
        this.activateRegUserId = false
        this.groupForTable = null
      }

      //change json_obj editor height
      this.changeHeightJSON_obj()
    },

    json_obj: function(obj) {
      this.activateRegUserId = !!obj?.reg_user_id
    },
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      'bot_settings',
      'current_tab_type',
      'all_bot_data_init',
      'custom_data_tables',
      'user',
      'get_custom_data_groups',
    ]),

    json_obj_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.json_obj);
      },
      set(value) {
        this.json_obj = value
      },
    },

    statistics_json_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.statistics_json);
      },
      set(value) {
        this.statistics_json = value
      },
    },

    front_json_in_editor: {
      cache: false,
      get() {
        return this._.cloneDeep(this.front_json);
      },
      set(value) {
        this.front_json = value
      },
    },
  },

  methods: {
    ...mapActions(['CustomDataAxiosGetTables',
      'CustomDataAxiosCreateModel',
      'CustomDataAxiosDeleteModel',
      'customDataAxiosGetGroups']),

    async createModel(){
        if ( !this.json_obj?.reg_user_id && this.activateRegUserId) {
          this.json_obj['reg_user_id'] = {"type":"INTEGER", "unique": "true"}
        }

       await this.CustomDataAxiosCreateModel({
        name: this.name,
        description:this.description,
        columns_json:this.json_obj,
        statistics_json:this.statistics_json,
        front_json: this.front_json,
        group_id: this.groupForTable >= 0 ? this.groupForTable : null,
      });
        await this.CustomDataAxiosGetTables({
          botId: this.$route?.params?.id,
        });
         this.tables = this.custom_data_tables

    },


    async deleteModel(){

       if (confirm(i18n.t('modules.customdata.model.confirm_delete'))) {
         await this.CustomDataAxiosDeleteModel({name: this.name})
         await this.CustomDataAxiosGetTables({
            botId: this.$route?.params?.id,
         });
         this.tables = this.custom_data_tables

         this.name = ""
         this.description = ""
         this.json_obj = {}
         this.statistics_json = {}
         this.front_json = {}
         this.activateRegUserId = false
       }

    },

    changeRegUserId(val) {
      if (val && !this.json_obj?.reg_user_id) {
        this.json_obj['reg_user_id'] = {"type":"INTEGER", "unique": "true"}
      } else {
        delete this.json_obj['reg_user_id']
      }
    },

    checkHeightJEditor(obj) {
      if(!obj) return '213px'

      return Object.keys(obj)?.length !== 0 ? '380px' : '213px'
    },

    changeHeightJSON_obj() {
      this.$nextTick(() => {
        if (!this.json_obj) this.json_obj_editor_height = '213px'

        let height = '380px'
        if (this.json_obj && this.$refs.builder?.clientHeight) {
          height = this.$refs.builder?.clientHeight - 75 + 'px'
        }

        this.json_obj_editor_height = Object.keys(this.json_obj)?.length !== 0 ? height.toString() : '213px'
      })
    },

    addField() {
      this.json_obj[this.new_field] = {"type":"STRING", "label": this.new_field.toString()}
      this.$nextTick(() => {
        this.new_field = null

        this.changeHeightJSON_obj()
      })
    },
    deleteField(key) {
      delete this.json_obj[key]
      this.$nextTick(() => {
        this.$forceUpdate()
        this.changeHeightJSON_obj()
      })
    },

    userCanSave() {
      const permissions = this.user?.role?.permissions?.custom_data?.sections
      let canSave = true

      if('customdata_create' in permissions) {
        canSave = permissions['customdata_create']?.canSave;
      }

      return canSave || !!this.user?.root
    },
  },
};
</script>


<style lang="scss" scoped>
.cd_create {
  $this: 'cd_create';
  position: relative;
  overflow: hidden;

  &_wrapper {
    height: calc(100vh - 60px) !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--top{}
    &--body{
      flex: 1 1 auto;

      .v-form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .v-sheet {
          flex: 1 1 auto;
        }
      }
    }
    &--footer{}
  }

  &__drawer::v-deep {
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      .v-form {
        flex: 1 1 auto;
      }
      .v-btn {
        max-height: 45px;
      }
    }
  }

  &__btn-ai-magic {
    z-index: 10;
    top: 168px;
    right: 0;
    border-radius: 25px 0 0 25px;
    text-transform: inherit;

    &:before {
      content: '';
      background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left:-2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 1;
      transition: opacity .3s ease-in-out;
      border-radius: inherit;
    }

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: inherit;
      left: 0;
      top: 0;
      border-radius: inherit;
    }

    .b_l_quad {
      position: absolute;
      left: -10px;
      top: -15px;
    }
    .b_l_quad .button_spots{
      @for $i from 1 through 20{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: -25 + ($i * 12) + px;
          top: 50 + px;
        }
      }
      @for $i from 20 through 40{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: -255 + ($i * 12) + px;
          top: -12 + px;
        }
      }
      @for $i from 40 through 46{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: 204px;
          top: -488 + ($i * 12) + px;
        }
      }

      @for $i from 46 through 52{
        &:nth-child(#{$i}){
          padding:random(3) + 2 + px;
          left: -10px;
          top: -568 + ($i * 12) + px;
        }
      }
    }
    .button_spots{
      position:absolute;
      border-radius:100px;
      background:green;
      opacity:0;
      animation:opacity 1s;
      @for $i from 1 through 52{
        &:nth-of-type(#{$i}){
          transform-origin:90px - random(10) 20px - random(10);
          background:hsla(350 + random(399) ,57% - random(10) ,65%,1);
          box-shadow:0px 0px 10px rgba(255,255,255,0.12);
          transition:all 1s + random(10)/10;
        }
      }
    }

    &:hover .button_spots{
      @for $i from 1 through 19{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
      @for $i from 20 through 40{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
      @for $i from 40 through 46{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
      @for $i from 46 through 54{
        &:nth-of-type(#{$i}){
          animation: spot-#{$i} .7s .4/random(10) + random(10)/10 + s linear infinite;
        }
      }
    }


    @for $i from 1 through 20{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(30px) translatex(-20px + $i*2);opacity:.6;}
      }
    }
    @for $i from 20 through 40{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(-30px) translatex(-50px + $i*2);opacity:.6;}
      }
    }
    @for $i from 40 through 45{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(-86px + $i*2) translatex(40px);opacity:.6;}
      }
    }
    @for $i from 46 through 54{
      @keyframes spot-#{$i}{
        from{opacity:0;}
        to{transform:translateY(-99px + $i*2) translatex(-40px);opacity:.6;}
      }
    }

    @keyframes opacity{
      from{}
      to{opacity:0;}
    }

    @keyframes rotate{
      from{opacity:.8}
      to{transform:rotate(360deg);opacity:.8}
    }

    @keyframes down{
      from{left:10px;}
      to{left:57px;}
    }

    @keyframes spew{
      from{opacity:0;}
      to{opacity:0.8;}
    }

    @keyframes final{
      from{opacity:1;}
      to{opacity:0;}
    }
    @keyframes finalbox{
      from{}
      to{width:50px;}
    }
    @keyframes tick{
      from{}
      to{transform:scale(1) rotate(-90deg)}
    }

    @keyframes glowing {
      0% { background-position: 0 0; }
      50% { background-position: 400% 0; }
      100% { background-position: 0 0; }
    }
  }
}


.json-example {
  font-size: 12px;
  color:#050038;
  background-color: #FFFBD1;
  padding: 20px;
}
</style>
