<template>
  <v-text-field
    v-model="localValue"
    v-mask="'##:##'"
    :label="label"
    :rules="[timeRule]"
    :error="hasError"
    :clearable="checkClearableValidations(entity_fields[field_key])"
    @input="handleInput"
    @blur="validateTime"
    :outlined="outlined"
    :solo="solo"
    flat
    dense
    :hide-details="hideDetails"
    :disabled="checkDisableValidations(entity_fields[field_key], field_key)"
    :prepend-icon="prependIcon"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TimeInput',
  props: {
    prependIcon: {
      type: String,
      default: '',
    },
    field_key: {
      type: String,
      default: '',
    },
    value: String,
    label: {
      type: String,
      default: 'Час (HH:mm)',
    },
    placeholder: {
      type: String,
      default: '00:00',
    },

    item_obj: {
      type: Object,
      default: () => ({}),
    },
    entity_fields: {
      type: Object,
      default: () => ({}),
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value || '',
      hasError: false,
    };
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
    localValue(val) {
      if(!this.hasError && this.timeRule(val) === true) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    handleInput(value) {
      const digitsOnly = value?.replace(/\D/g, '');
      if (digitsOnly?.length === 4) {
        this.localValue = `${digitsOnly.slice(0, 2)}:${digitsOnly.slice(2, 4)}`;
      } else {
        this.localValue = value;
      }
      this.validateTime();
    },
    validateTime() {
      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      this.hasError = !timeRegex.test(this.localValue);
    },
    timeRule(value) {
      if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(value)) {
        return 'Неправильний формат часу';
      }

      if(this.checkTimeValidations(this.localValue, this.entity_fields[this.field_key])) {
        return 'Неприпустимий час';
      }

      return true;
    },



    checkClearableValidations(field) {
      let clearable = true

      if(field?.validate?.disable_clearable) {
        const required_field = field?.validate?.disable_clearable?.required_field

        if(
          required_field
          && this.item_obj?.[required_field]
        ) clearable = false
      }

      return clearable

    },
    checkDisableValidations(field, key) {
      let disabled = false

      if(field?.validate?.required_field_empty) {
        //val from another field
        const val = this.item_obj[field?.validate?.required_field_empty]
        if(!val) disabled = true
      }
      if(field?.validate?.required_field_not_empty) {
        //val from another field
        const val = this.item_obj[field?.validate?.required_field_not_empty]
        if(val) disabled = true
      }

      if(
        field?.validate?.disabled_in_table_on_edit === true
        && this.item_obj[key]
      ) disabled = true

      return disabled
    },
    checkTimeValidations(time, field) {
      let success = true

      if(
        field?.validate?.rules?.type === 'time'
        && field?.validate?.rules?.action
        && !field?.validate?.rules?.ignore_in_table
      ) {
        let hours = 0, minutes = 0;
        const now = new Date();
        let timeString = this.item_obj?.[field?.validate?.rules?.val_from_field];

        if(timeString) {
          //fix if chosen date is not Today
          if(
            this.entity_fields?.[field?.validate?.rules?.val_from_field]
              ?.validate?.rules?.type === 'date'
          ) {
            if(this.isToday(timeString)) {
              timeString = `${now.getHours()}:${now.getMinutes()}`
            } else {
              timeString = '00:00'
            }
          }

          [hours, minutes] = timeString.split(':').map(Number);
        } else if(!field?.validate?.rules?.val_from_field) {
          hours = now.getHours();
          minutes = now.getMinutes();
        }

        const timeMinutes = this.timeToMinutes(time);
        const refMinutes = hours * 60 + minutes;

        const compare = this.$getComparator(field?.validate?.rules?.action)
        if(compare( timeMinutes, refMinutes )) success = false
      }

      return success
    },
    isToday(dateString) {
      const [day, month, year] = dateString.split('.').map(Number);
      const givenDate = new Date(year, month - 1, day);

      const today = new Date();
      return givenDate.getDate() === today.getDate() &&
        givenDate.getMonth() === today.getMonth() &&
        givenDate.getFullYear() === today.getFullYear();
    },
    timeToMinutes(timeStr) {
      const [h, m] = timeStr.split(':').map(Number);
      return h * 60 + m;
    }
  },
};
</script>